import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { debounce } from 'throttle-debounce';
import store from '../index';
import { REDUCER_KEY_COMMON } from '../../constants';

interface ICommonState {
  windowWidth: number,
}

const initialState: ICommonState = {
  windowWidth: window.innerWidth,
};

const commonSlice = createSlice<ICommonState,
  SliceCaseReducers<ICommonState>,
  typeof REDUCER_KEY_COMMON>({
    name: REDUCER_KEY_COMMON,
    initialState,
    reducers: {
      windowResize: (state, action: PayloadAction<number>) => {
        state.windowWidth = action.payload;
      },
    },
  });

const handleWindowResize: EventListener = (event: Event) => {
  store.dispatch(commonSlice.actions.windowResize(window.innerWidth));
};

window.addEventListener('resize', debounce(300, handleWindowResize));

export default commonSlice.reducer;
