import { useState } from 'react';

const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModalOpen = () => {
    if (modalIsOpen) {
      setModalIsOpen(false);
    } else {
      setModalIsOpen(true);
    }
  };

  return {
    modalIsOpen,
    toggleModalOpen,
  };
};

export default useModal;
