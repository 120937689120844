import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '..';
import ApiClient from '../../api/ApiClient';
import {
  ACTION_NAME_FETCH_LICENSE_TOKEN, ACTION_NAME_SET_PLAY,
  ACTION_NAME_SET_STOP,
  REDUCER_KEY_PLAYER, VERA_TYPE,
} from '../../constants';
import { IPlaylist } from '../../types';

interface IPlaylistState {
  playlist: IPlaylist | undefined;
  licenseToken: string;
  isFetchingLicenseToken: boolean;
  isPlaying: boolean;
  playingTrackId: string | undefined;
  isShuffled: boolean;
  startedListeningDate: Date | undefined;
  isShowedRateModal: boolean;
  licenseTokenError: string;
  trackToConfirm: string;
}

const initialState: IPlaylistState = {
  playlist: undefined,
  licenseToken: '',
  isFetchingLicenseToken: false,
  isPlaying: false,
  playingTrackId: undefined,
  isShuffled: false,
  startedListeningDate: undefined,
  isShowedRateModal: false,
  licenseTokenError: '',
  trackToConfirm: '',
};

interface IFetchLicenseToken {
  drm_type: string | 'Widevine';
  track_id: string;
  profile_id?: string;
  playlist_name: string;
  force?: boolean;
}

const fetchLicenseToken = createAsyncThunk<AxiosResponse<any>, IFetchLicenseToken>(
  `${REDUCER_KEY_PLAYER}/${ACTION_NAME_FETCH_LICENSE_TOKEN}`,
  async ({
    drm_type,
    track_id,
    profile_id,
    playlist_name,
    force,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.fetchLicenseToken({
      drm_type, track_id, profile_id, playlist_name, force,
    });
    return response;
  },
);

const setPlay = createAsyncThunk<
  AxiosResponse<any>,
  {isPlaying: boolean},
  {state: RootState}
>(
  `${REDUCER_KEY_PLAYER}/${ACTION_NAME_SET_PLAY}`,
  async ({ isPlaying }, { getState }) => {
    const state = getState();
    const track_id = state.player.playingTrackId;
    const isPro = state.auth.veraType === VERA_TYPE.PRO;
    const profile_id = isPro
      ? (state.auth.resident_id as string)
      : (state.profile.data?.id as string) || (state.profile.profiles?.[0].id as string);

    if (track_id) {
      await ApiClient.trackAction({
        pause: !isPlaying,
        profile_id,
        track_id,
      });
    }

    throw new Error();
  },
);

const clearPlayer = createAsyncThunk<
  AxiosResponse<any>, Record<string, unknown>, {state: RootState}
  >(
    `${REDUCER_KEY_PLAYER}/${ACTION_NAME_SET_STOP}`,
    async (_, { getState }) => {
      const state = getState();
      const track_id = state.player.playingTrackId;
      const isPro = state.auth.veraType === VERA_TYPE.PRO;
      const profile_id = isPro
        ? (state.auth.resident_id as string)
        : (state.profile.data?.id as string) || (state.profile.profiles?.[0].id as string);
      if (track_id && profile_id) {
        const response = await ApiClient.trackAction({
          pause: true,
          profile_id,
          track_id,
        });
        return response;
      }
      throw new Error();
    },
  );

const playlistSlice = createSlice<IPlaylistState,
  SliceCaseReducers<IPlaylistState>,
  typeof REDUCER_KEY_PLAYER>({
    name: REDUCER_KEY_PLAYER,
    initialState,
    reducers: {
      setPlaylist: (state, action: PayloadAction<IPlaylist>) => {
        state.playlist = action.payload;
      },
      setPlay: (state, action: PayloadAction<{ isPlaying: boolean }>) => {
        state.isPlaying = action.payload.isPlaying;
      },
      setPlayingTrack: (state, action: PayloadAction<{ trackId: string }>) => {
        state.playingTrackId = action.payload.trackId;
      },
      setShufflePlay: (state, action: PayloadAction<{ isShuffled: boolean }>) => {
        state.isShuffled = action.payload.isShuffled;
      },
      setStartedListeningDate: (state, action: PayloadAction<{
        startedListeningDate: Date | undefined,
      }>) => {
        state.startedListeningDate = action.payload.startedListeningDate;
      },
      setIsShowedRateModal: (state, action: PayloadAction<{ isShowedRateModal: boolean }>) => {
        state.isShowedRateModal = action.payload.isShowedRateModal;
      },
      clearLicenseError: (state) => {
        state.licenseTokenError = '';
      },
      confirmPlaying: (state, action: PayloadAction<{ trackId: string }>) => {
        state.trackToConfirm = action.payload.trackId;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(setPlay.pending, (state, { meta }) => {
          state.isPlaying = meta.arg.isPlaying;
        })
        .addCase(clearPlayer.fulfilled, (state) => {
          state.playlist = undefined;
          state.licenseToken = '';
          state.isFetchingLicenseToken = false;
          state.isPlaying = false;
          state.playingTrackId = undefined;
          state.isShuffled = false;
          state.startedListeningDate = undefined;
          state.isShowedRateModal = false;
        })
        .addCase(clearPlayer.rejected, (state) => {
          state.playlist = undefined;
          state.licenseToken = '';
          state.isFetchingLicenseToken = false;
          state.isPlaying = false;
          state.playingTrackId = undefined;
          state.isShuffled = false;
          state.startedListeningDate = undefined;
          state.isShowedRateModal = false;
        })
        .addCase(fetchLicenseToken.pending, (state) => {
          state.isFetchingLicenseToken = true;
        })
        .addCase(fetchLicenseToken.fulfilled, (state, { payload }) => {
          state.licenseToken = payload.data.license_token;
          state.isFetchingLicenseToken = false;
        })
        .addCase(fetchLicenseToken.rejected, (state, { payload }) => {
          state.licenseToken = '';
          // @ts-ignore
          state.licenseTokenError = payload?.error?.message || '';
          state.isFetchingLicenseToken = false;
        });
    },
  });

const {
  setPlaylist,
  setPlayingTrack,
  setShufflePlay,
  setStartedListeningDate,
  setIsShowedRateModal,
  clearLicenseError,
  confirmPlaying,
} = playlistSlice.actions;

export {
  setPlaylist,
  setPlay,
  setPlayingTrack,
  setShufflePlay,
  fetchLicenseToken,
  setStartedListeningDate,
  setIsShowedRateModal,
  clearPlayer,
  clearLicenseError,
  confirmPlaying,
};

export default playlistSlice.reducer;
