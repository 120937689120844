import React from 'react';
import Button, { EButtonType } from '../Button';
import OverlayPlayerBanner from './index';

interface IReminisceOverlayPlayerBanner {
  onStartListening(): void;
  onShufflePlay(): void;
}

const ReminisceOverlayPlayerBanner: React.FunctionComponent<IReminisceOverlayPlayerBanner> = ({
  onStartListening,
  onShufflePlay,
}) => (
  <OverlayPlayerBanner
    title="Reminisce"
    text="For someone feeling confused or in a brain fog"
    image="/images/reminisceIllustration.png"
  >
    <div className="greeting-banner__buttons">
      <div className="row">
        <div className="col-auto">
          <Button
            type="button"
            color="primary"
            size="middle"
            view={EButtonType.REMINISCE}
            className="greeting-banner__button"
            onClick={onStartListening}
          >
            Start Listening
          </Button>
        </div>
        <div className="col-auto">
          <Button
            type="button"
            color="secondary"
            size="middle"
            view={EButtonType.REMINISCE}
            className="greeting-banner__button"
            onClick={onShufflePlay}
          >
            Shuffle Play
          </Button>
        </div>
      </div>
    </div>
  </OverlayPlayerBanner>
);

export default ReminisceOverlayPlayerBanner;
