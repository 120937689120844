import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_COUNTRIES,
  ACTION_NAME_FETCH_COUNTRIES,
  ACTION_NAME_FETCH_NOTIFY_COUNTRIES,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import { ICountries } from '../../types';

interface ICountriesState {
  isFetching: boolean;
  countries: Array<ICountries>;
  notifyCountries: Array<ICountries>;

}

const initialState: ICountriesState = {
  isFetching: false,
  countries: [],
  notifyCountries: [],
};

const fetchCountries = createAsyncThunk<AxiosResponse<Array<ICountries>>>(
  `${REDUCER_KEY_COUNTRIES}/${ACTION_NAME_FETCH_COUNTRIES}`,
  async () => {
    const response = await ApiClient.fetchCountries();
    return response;
  },
);

const fetchNotifyCountries = createAsyncThunk<AxiosResponse<Array<ICountries>>>(
  `${REDUCER_KEY_COUNTRIES}/${ACTION_NAME_FETCH_NOTIFY_COUNTRIES}`,
  async () => {
    const response = await ApiClient.fetchNotifyCountries();
    return response;
  },
);

const countriesSlice = createSlice<ICountriesState, SliceCaseReducers<ICountriesState>,
  typeof REDUCER_KEY_COUNTRIES>({
    name: REDUCER_KEY_COUNTRIES,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchCountries.fulfilled, (state, { payload }) => {
          state.countries = payload?.data || [];
        })
        .addCase(fetchNotifyCountries.fulfilled, (state, { payload }) => {
          state.notifyCountries = payload.data;
        })
        .addMatcher(
          (action) => action.type.endsWith('/pending'),
          (state) => {
            state.isFetching = true;
          },
        )
        .addMatcher(
          (action) => action.type.endsWith('/rejected') || action.type.endsWith('/fulfilled'),
          (state) => {
            state.isFetching = false;
          },
        );
    },
  });

export {
  fetchCountries,
  fetchNotifyCountries,
};

export default countriesSlice.reducer;
