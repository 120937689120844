export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'https://api.musichealth.ai';
export const REACT_APP_LICENSE_PALLYCON_URL = process.env.REACT_APP_LICENSE_PALLYCON_URL || 'https://license.pallycon.com/ri/licenseManager.do';
export const REACT_APP_FPS_CERT_URL = process.env.REACT_APP_FPS_CERT_URL || 'https://license-global.pallycon.com/ri/fpsKeyManager.do';
export const REACT_APP_APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID || 'com.veramusic.play.service';
export const REACT_APP_APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI || 'https://play.musichealth.ai/login';
export const REACT_APP_FEATURE_SOCIAL_SIGNIN = process.env.REACT_APP_FEATURE_SOCIAL_SIGNIN || 'off';
export const REACT_APP_FEATURE_VERAPRO = process.env.REACT_APP_FEATURE_VERAPRO || 'off';
export const REACT_APP_PROFILES_CHECK = 60000;
// Required
export const REACT_APP_YOUTUBE_PLAYLIST_ID = process.env.REACT_APP_YOUTUBE_PLAYLIST_ID || '';
export const REACT_APP_YOUTUBE_KEY = process.env.REACT_APP_YOUTUBE_KEY || '';
export const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';
export const REACT_APP_PALLYCON_SITE_ID = process.env.REACT_APP_PALLYCON_SITE_ID || '';
export const REACT_APP_GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || '';
export const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
export const REACT_APP_FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '';
