import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import ROUTES from './constants';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { REDUCER_KEY_AUTH } from '../constants';
import AuthLayout from '../layout/AuthLayout';
import ApiClient from '../api/ApiClient';
import { appendTokens } from '../store/reducers/authReducer';
import { fetchProfiles } from '../store/reducers/profileReducer';

const PrivateRoute: React.FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const {
    auth,
  } = useAppSelector((state) => ({
    auth: state[REDUCER_KEY_AUTH],
  }));
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const tokenExternal = searchParams.get('token');
  const refreshTokenExternal = searchParams.get('refreshToken');

  useEffect(() => {
    (async () => {
      if (refreshTokenExternal && tokenExternal) {
        const response = await ApiClient.refreshUserToken(refreshTokenExternal);
        const token = response.data.access;
        dispatch(appendTokens({ token, refreshToken: refreshTokenExternal }));
        ApiClient.setToken(response.data.access, refreshTokenExternal);
        await dispatch(fetchProfiles());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!Component) return null;

  if (
    auth.isRegistering
    && history.location.pathname !== ROUTES.LOGIN
    && history.location.pathname !== ROUTES.SIGNUP
  ) {
    return <Redirect to={ROUTES.CONTINUE_ONBOARDING} />;
  }

  if (
    auth.isGuest
    && (!auth.token || !auth.refreshToken)
    && !tokenExternal
    && !refreshTokenExternal
  ) {
    return <Redirect to={ROUTES.START} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

export default PrivateRoute;
