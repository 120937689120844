import * as React from 'react';
import ConfirmPlayingModal from './components/ModalWindow/ConfirmPlayingModal';
import SessionExpiredModal from './components/ModalWindow/SessionExpiredModal';
import useIntervalFetchProfilesState from './hooks/useIntervalFetchProfilesState';

export default function AppController() {
  useIntervalFetchProfilesState();

  return (
    <React.Fragment>
      <SessionExpiredModal />
      <ConfirmPlayingModal />
    </React.Fragment>
  );
}
