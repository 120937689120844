import React from 'react';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import ModalWindow, { ModalTitle } from './index';
import Checkbox from '../Checkbox';
import Textarea from '../Textarea';
import Button from '../Button';
import { IArtist } from '../../types';
import { useAppDispatch } from '../../store/hooks';
import { updateFavouriteArtistes } from '../../store/reducers/artistsReducer';
import { updateTracksPreferences } from '../../store/reducers/tracksReducer';
import useProfileData from '../../hooks/useProfileData';

interface ILikeModal {
  modalIsOpen: boolean;
  toggleModalOpen(): void;
  track: { id: string, title: string, mainArtist: IArtist } | undefined;
  onClose?(): void;
}

interface ILikeData {
  like: Array<string>;
  comment: string;
}

const LikeModal: React.FunctionComponent<ILikeModal> = ({
  modalIsOpen,
  toggleModalOpen,
  track,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { profileData } = useProfileData();

  const formikLike = useFormik<ILikeData>({
    initialValues: {
      like: [],
      comment: '',
    },
    validationSchema: Yup.object({
      like: Yup.array().of(
        Yup.string(),
      ).required('Required'),
      comment: Yup.string()
        .min(3),
    }),
    onSubmit: (values: ILikeData, formikHelpers: FormikHelpers<ILikeData>) => {
      if (track && profileData) {
        if (values.like.includes('Song') || values.like.includes('Other')) {
          if (values.comment !== '') {
            dispatch(updateTracksPreferences({
              id: `${profileData.id}`,
              action: 'like',
              tracks: [track.id],
              comment: values.comment,
            }));
          } else {
            dispatch(updateTracksPreferences({
              id: `${profileData.id}`,
              action: 'like',
              tracks: [track.id],
            }));
          }
        }
        if (values.like.includes('Artist')) {
          if (values.comment !== '') {
            dispatch(updateFavouriteArtistes({
              id: `${profileData.id}`,
              action: 'like',
              artists: [track.mainArtist.id],
              comment: values.comment,
            }));
          } else {
            dispatch(updateFavouriteArtistes({
              id: `${profileData.id}`,
              action: 'like',
              artists: [track.mainArtist.id],
            }));
          }
        }
        toggleModalOpen();
        formikHelpers.resetForm();
        if (onClose) {
          onClose();
        }
      }
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
  } = formikLike;

  const handleClose = () => {
    toggleModalOpen();
    if (onClose) {
      onClose();
    }
  };

  return (
    <ModalWindow
      modalIsOpen={modalIsOpen}
      onCloseModal={toggleModalOpen}
    >
      <ModalTitle
        // @ts-ignore
        title={`Like Song - ${track?.title}`}
      />
      <p className="modal__text">
        You’re about to &quot;heart icon&quot; a song. Tell us why.
      </p>
      <form
        onSubmit={handleSubmit}
      >
        <div className="row">
          <div className="col-6 col-md-auto">
            <Checkbox
              name="like"
              placeholder="Song"
              onChange={handleChange}
              value="Song"
              checked={values.like.includes('Song')}
              button
              id="Song"
            />
          </div>
          <div className="col-6 col-md-auto">
            <Checkbox
              name="like"
              placeholder="Artist"
              onChange={handleChange}
              value="Artist"
              checked={values.like.includes('Artist')}
              button
              id="Artist"
            />
          </div>
          <div className="col-6 col-md-auto">
            <Checkbox
              name="like"
              placeholder="Other"
              onChange={handleChange}
              value="Other"
              checked={values.like.includes('Other')}
              button
              id="Other"
            />
          </div>
        </div>
        <Textarea
          name="comments"
          placeholder="e.g. this song makes me feel good"
          label="Additional Comments (Optional)"
          value={values.comment}
          onChange={handleChange}
          error={errors.comment}
        />
        <div className="row flex-row-reverse flex-md-row">
          <div className="col-6 col-md-auto">
            <Button
              type="submit"
              color="primary"
              size="middle"
              disabled={values.like.length < 1}
            >
              Like Song
            </Button>
          </div>
          <div className="col-6 col-md-auto">
            <Button
              type="button"
              color="secondary"
              size="middle"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </ModalWindow>
  );
};

export default LikeModal;
