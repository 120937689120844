import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_STRIPE,
  ACTION_NAME_CHECKOUT,
  ACTION_NAME_FETCH_PORTAL_LINK,
  ACTION_NAME_FETCH_MEMBERSHIP_DETAILS,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import { IMembershipDetails } from '../../types';

interface IStripeState {
  isFetching: boolean;
  details: IMembershipDetails | undefined;
}

const initialState: IStripeState = {
  isFetching: false,
  details: undefined,
};

const checkoutPayment = createAsyncThunk<AxiosResponse<any>, {
  membership_type: string,
  location: any,
}>(
  `${REDUCER_KEY_STRIPE}/${ACTION_NAME_CHECKOUT}`,
  async ({
    membership_type,
    location,
  }, { dispatch }) => {
    const response = await ApiClient.checkoutStripe(`${membership_type}`, location);
    window.location.replace(response.data.checkout_url);
    return response;
  },
);

const fetchPortalLink = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_STRIPE}/${ACTION_NAME_FETCH_PORTAL_LINK}`,
  async () => {
    const response = await ApiClient.fetchPortalLink();
    window.location.replace(response.data.customer_portal_url);
    return response;
  },
);

const fetchMembershipDetails = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_STRIPE}/${ACTION_NAME_FETCH_MEMBERSHIP_DETAILS}`,
  async () => {
    const response = await ApiClient.fetchMembershipDetails();
    return response;
  },
);

const stripeSlice = createSlice<IStripeState,
  SliceCaseReducers<IStripeState>,
  typeof REDUCER_KEY_STRIPE>({
    name: REDUCER_KEY_STRIPE,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(checkoutPayment.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(checkoutPayment.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(checkoutPayment.fulfilled, (state, { payload }) => {
          state.isFetching = false;
        })
        .addCase(fetchMembershipDetails.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchMembershipDetails.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchMembershipDetails.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          state.details = payload?.data || {};
        })
        .addCase(fetchPortalLink.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchPortalLink.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchPortalLink.fulfilled, (state, { payload }) => {
          state.isFetching = false;
        });
    },
  });

const { actions, reducer } = stripeSlice;

export {
  checkoutPayment,
  fetchPortalLink,
  fetchMembershipDetails,
};

export default reducer;
