import React from 'react';

const GuestLayout: React.FunctionComponent<Record<string, unknown>> = ({
  children,
}) => (
  <div className="guest-wrapper">
    {children}
  </div>
);

export default GuestLayout;
