import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_ARTISTS,
  ACTION_NAME_SEARCH_ARTISTS,
  ACTION_NAME_FETCH_FAVOURITE_ARTISTS,
  ACTION_NAME_UPDATE_FAVOURITE_ARTISTS,
  ACTION_NAME_FETCH_SUGGESTED_ARTISTS,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import { IArtist } from '../../types';

const initialFavoritesArtistsState = { likes: [], dislikes: [] };
interface IArtistsState {
  isFetching: boolean;
  searchResults: Array<IArtist>;
  favoritesArtists: { likes: Array<IArtist>; dislikes: Array<IArtist> };
  suggestedArtists: IArtist[];
}

const initialState: IArtistsState = {
  isFetching: false,
  searchResults: [],
  favoritesArtists: initialFavoritesArtistsState,
  suggestedArtists: [],
};

const searchArtists = createAsyncThunk<
  AxiosResponse<Array<IArtist>>,
  {
    id: string;
    keywords: string;
    exclude_artists?: Array<string>;
  }
>(
  `${REDUCER_KEY_ARTISTS}/${ACTION_NAME_SEARCH_ARTISTS}`,
  async ({ id, keywords, exclude_artists }) => {
    const response = await ApiClient.searchArtists(
      id,
      keywords,
      exclude_artists,
    );
    return response;
  },
);

const fetchFavouriteArtistes = createAsyncThunk<
  AxiosResponse<any>,
  { id: string }
>(
  `${REDUCER_KEY_ARTISTS}/${ACTION_NAME_FETCH_FAVOURITE_ARTISTS}`,
  async ({ id }) => {
    const response = await ApiClient.fetchFavouriteArtistes(id);
    return response;
  },
);

const updateFavouriteArtistes = createAsyncThunk<
  AxiosResponse<any>,
  {
    id: any;
    action: string;
    artists: Array<string>;
    comment?: string;
  }
>(
  `${REDUCER_KEY_ARTISTS}/${ACTION_NAME_UPDATE_FAVOURITE_ARTISTS}`,
  async ({
    id,
    action,
    artists,
    comment,
  }) => {
    const response = await ApiClient.updateFavouriteArtists(id, action, artists, comment);
    return response;
  },
);

const fetchSuggestedArtistes = createAsyncThunk<
  AxiosResponse<any>,
  {
    id: any;
    exclude_artists?: Array<string>;
    keywords?: string;
  }
>(
  `${REDUCER_KEY_ARTISTS}/${ACTION_NAME_FETCH_SUGGESTED_ARTISTS}`,
  async ({ id, exclude_artists, keywords }) => {
    const response = await ApiClient.fetchSuggestedArtistes(
      id,
      exclude_artists,
      keywords,
    );
    return response;
  },
);

const artistsSlice = createSlice<
  IArtistsState,
  SliceCaseReducers<IArtistsState>,
  typeof REDUCER_KEY_ARTISTS
>({
  name: REDUCER_KEY_ARTISTS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchArtists.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(searchArtists.fulfilled, (state, { payload }) => {
        state.searchResults = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchFavouriteArtistes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchFavouriteArtistes.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchFavouriteArtistes.fulfilled, (state, { payload }) => {
        state.favoritesArtists = payload?.data;
        state.isFetching = false;
      })
      .addCase(updateFavouriteArtistes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updateFavouriteArtistes.fulfilled, (state, { payload }) => {
        state.favoritesArtists = payload?.data || initialFavoritesArtistsState;
        state.isFetching = false;
      })
      .addCase(fetchSuggestedArtistes.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchSuggestedArtistes.fulfilled, (state, { payload }) => {
        state.suggestedArtists = payload.data.suggested_artists;
        state.isFetching = false;
      });
  },
});

export {
  searchArtists,
  fetchFavouriteArtistes,
  updateFavouriteArtistes,
  fetchSuggestedArtistes,
};

export default artistsSlice.reducer;
