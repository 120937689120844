import React from 'react';
import classNames from 'classnames';

interface IRadioButtonCard {
  className?: string;
  disabled?: boolean;
  error?: string;
  value: string | undefined;
  onChange (event: React.ChangeEvent<any>): void;
  title: string;
  price: string;
  priceByMonth: string;
  name: string;
  checked: boolean;
  id: string;
  hideHead?: boolean;
  size?: 'middle';
  headText?: string;
  period?: string;
  location?: string;
  description?: string;
}

const RadioButtonCard: React.FunctionComponent<IRadioButtonCard> = ({
  className,
  disabled,
  error,
  value,
  onChange,
  title,
  price,
  priceByMonth,
  name,
  checked,
  id,
  hideHead,
  size,
  headText,
  period,
  location,
  description,
}) => (
  <label
    className={classNames('field field--radio', {
      [`${className}`]: className,
      'field--checked': checked,
      'field--disabled': disabled,
      'field--error': error,
    })}
    htmlFor={id}
  >
    <div
      className={classNames('radio-button radio-button--card', {
        [`radio-button--${size}-card`]: size,
      })}
    >
      <input
        type="radio"
        disabled={disabled}
        onChange={onChange}
        value={value}
        name={name}
        id={id}
        checked={checked}
      />
      <div
        className="radio-button__head"
        hidden={hideHead}
      >
        {headText || 'MOST POPULAR'}
      </div>
      <div className="radio-button__title">
        {title}
      </div>
      <div className="radio-button__price">
        <span>{price}</span>
        {
          period
          && (
            <span className="radio-button__period">{period}</span>
          )
        }
      </div>
      {
        !size
        && description
        && (
          <div className="radio-button__price-by-month">
            {description}
          </div>
        )
      }
    </div>
  </label>
);

export default RadioButtonCard;
