import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import {
  REDUCER_KEY_CHAT,
} from '../../constants';

interface IChatState {
  isOpened: boolean;
}

const initialState: IChatState = {
  isOpened: false,
};

const chatSlice = createSlice<IChatState,
  SliceCaseReducers<IChatState>,
  typeof REDUCER_KEY_CHAT>({
    name: REDUCER_KEY_CHAT,
    initialState,
    reducers: {
      setOpenChat: (state, action: PayloadAction<{ isOpened: boolean }>) => {
        state.isOpened = action.payload.isOpened;
      },
    },
  });

const {
  setOpenChat,
} = chatSlice.actions;

export {
  setOpenChat,
};

export default chatSlice.reducer;
