import React from 'react';
import classNames from 'classnames';
import { FormikErrors } from 'formik';
import Icon, { ICON_NAMES } from '../Icons';

interface IInputSearch {
  placeholder: string;
  value: string | undefined;
  name: string;
  onChange (event: React.ChangeEvent<HTMLInputElement>): void;
  error?: string | null;
  label?: string;
  disabled?: boolean;
  className?: string;
  buttonText?: string;
  onButtonClick ?(event: React.ChangeEvent<any>): void;
  setFieldTouched ?(field: string, touched?: boolean, shouldValidate?: boolean | undefined):
    Promise<FormikErrors<any>> | Promise<void>;
}

const InputSearch: React.FunctionComponent<IInputSearch> = ({
  disabled,
  placeholder,
  label,
  value,
  onChange,
  error,
  name,
  className,
  buttonText,
  onButtonClick,
  setFieldTouched,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setFieldTouched) {
      setFieldTouched(name, true);
    }
    onChange(event);
  };

  return (
    <label
      className={classNames('field field--input', {
        [`${className}`]: className,
        'field--error': error,
        'field--disabled': disabled,
      })}
      htmlFor={name}
    >
      {
        label
        && (
          <span
            className="field__label"
          >
            {label}
          </span>
        )
      }
      <div
        className={classNames('field__container field__container--search', {
          'field__container--with-button': onButtonClick,
        })}
      >
        <Icon name={ICON_NAMES.SEARCH} className="field__search-icon" />
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          className={classNames('field__input field__input--search', {
            'field__input--with-button': onButtonClick,
          })}
          value={value}
          onChange={handleChange}
        />

        {
          onButtonClick
          && (
            <button
              className="field__inside-button"
              onClick={onButtonClick}
              disabled={!value || !!error}
            >
              { buttonText || 'Click' }
            </button>
          )
        }
      </div>
      {
        error
        && (
          <span className="field__error">{error}</span>
        )
      }
    </label>
  );
};

export default InputSearch;
