enum ICON_NAMES {
  ACCOUNT = 'account',
  ACCEPT = 'accept',
  ACCEPTED = 'accepted',
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  AVATAR = 'avatar',
  CHAT = 'chat',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_RIGHT_THIN = 'chevron-right-thin',
  CLOSE = 'close',
  CLOSE_BIG = 'close-big',
  CHECKED = 'checked',
  DECREASE = 'decrease',
  DISLIKE = 'dislike',
  DISLIKED = 'disliked',
  HOME = 'home',
  INCREASE = 'increase',
  LIKE = 'like',
  LOGO = 'logo',
  LOGO_PRO = 'logo-pro',
  LOGO_PRO_LEFT_BAR = 'logo-pro-left-bar',
  MORE = 'more',
  MUTE = 'mute',
  NEXT = 'next',
  NEXT_ROUNDED = 'next-rounded',
  PAUSE = 'pause',
  PAUSE_COLORED = 'pause-colored',
  PEN = 'pen',
  PHOTO_CAMERA = 'photo-camera',
  PLANS = 'plans',
  PLAY = 'play',
  PLAY_COLORED = 'play-colored',
  PLAY_OPACITY = 'play-opacity',
  PLAY_LIST = 'playlist',
  PLUS = 'plus',
  PREVIOUS = 'previous',
  PROFILE = 'profile',
  REFRESH = 'refresh',
  REJECT = 'reject',
  REPEAT = 'repeat',
  SEARCH = 'search',
  SELECTED = 'selected',
  SETTINGS = 'settings',
  SHUFFLE = 'shuffle',
  SPEAK = 'speak',
  STAR = 'star',
  SWITCH = 'switch',
  TRASH = 'trash',
  UNDO = 'undo',
  UNMUTE = 'unmute',
  UPLOAD = 'upload',
  VOLUME = 'volume',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  CHECK = 'check',
}

export default ICON_NAMES;
