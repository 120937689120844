import React from 'react';
import ModalWindow, { ModalTitle } from './index';
import {
  useAppDispatch,
  useAppSelector,
} from '../../store/hooks';
import { REDUCER_KEY_MODAL } from '../../constants';
import { setOpen } from '../../store/reducers/modalReducer';

const ErrorModal: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => ({
    isOpen: state[REDUCER_KEY_MODAL].isOpen,
  }));

  const handleModalClose = () => {
    dispatch(setOpen({ isOpen: false }));
  };

  return (
    <ModalWindow
      modalIsOpen={isOpen}
      onCloseModal={handleModalClose}
    >
      <ModalTitle
        title="Something went wrong"
      />
      <p className="modal__text">
        Oops, something went wrong with our system. It’s not you, it’s us.
        Please close the app and come back again later. Thank you.
      </p>
      <p className="modal__text">
        -The Vera Team
      </p>
    </ModalWindow>
  );
};

export default ErrorModal;
