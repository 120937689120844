import React from 'react';
import classNames from 'classnames';

interface IEntityCover {
  title: string;
  src?: string;
  containerClassName?: string;
  className?: string;
  backgroundColor?: 'gray';
  rounded?: boolean;
  size?: 'middle' | 'big';
  disabled?: boolean;
}

const EntityCover: React.FunctionComponent<IEntityCover> = ({
  title,
  src,
  containerClassName,
  className,
  backgroundColor,
  rounded,
  size,
  disabled,
}) => {
  if (src) {
    return (
      <div
        className={classNames('cover-container', {
          [`${containerClassName}`]: !!containerClassName,
          [`cover-container--${backgroundColor}`]: !!backgroundColor,
          'cover-container--rounded': rounded,
          [`cover-container--${size}`]: !!size,
          'cover-container--gray': disabled,
        })}
      >
        <img
          src={src}
          alt={title}
          title={title}
          className={classNames('cover-image', {
            [`${className}`]: !!className,
            'cover-image--disabled': disabled,
          })}
        />
      </div>
    );
  }
  return (
    <div
      className={classNames('cover-container', {
        [`${containerClassName}`]: !!containerClassName,
        [`cover-container--${backgroundColor}`]: !!backgroundColor,
        'cover-container--rounded': rounded,
        [`cover-container--${size}`]: !!size,
        'cover-container--gray': disabled,
      })}
    >
      {title?.charAt(0)}
    </div>
  );
};

export default EntityCover;
