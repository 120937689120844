import React from 'react';
import ModalWindow from './index';
import FrameModal from '../../source/LoginPro.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { REDUCER_KEY_APP_SETTINGS } from '../../constants';
import { setTrialReminderWasShown } from '../../store/reducers/appSettingsReducer';
import isTrialsEndInSevenDaysSelector from '../../store/selectors/isTrialsEndInSevenDaysSelector';

const TrialModal: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { isTrialEndInSevenDays, isTrialReminderWasShown } = useAppSelector(
    (state) => ({
      isTrialEndInSevenDays: isTrialsEndInSevenDaysSelector(state),
      isTrialReminderWasShown:
        state[REDUCER_KEY_APP_SETTINGS]?.isTrialReminderWasShown,
    }),
  );

  const isModalShown = !!(isTrialEndInSevenDays && !isTrialReminderWasShown);

  const handleModalHide = () => {
    dispatch(setTrialReminderWasShown({}));
  };

  return (
    <ModalWindow modalIsOpen={isModalShown} onCloseModal={handleModalHide}>
      <div className="trial-reminder">
        <h2 className="modal__title text-center">
          Your 14 day free trial is ending in 7 days
        </h2>
        <p className="modal__text text-center mb-0">
          Your 14-day free trial will end in 7 days. After this time your
          preferred payment method will be automatically debited for your
          selected subscription period.
        </p>
        <img alt="" className="image trial-reminder__image" src={FrameModal} />
        <p className="modal__text text-center mb-0">
          You can cancel your free trial at any time under the ‘Membership’
          section of Vera. For more help on Vera or questions on your
          membership,{' '}
          <a className="font-bold" href="mailto:help@veramusic.com">
            Contact Us
          </a>
        </p>
      </div>
    </ModalWindow>
  );
};

export default TrialModal;
