import React from 'react';
import ModalWindow, { ModalTitle } from './index';
import Button from '../Button';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { REDUCER_KEY_PLAYER } from '../../constants';
import { confirmPlaying, setPlay, setPlayingTrack } from '../../store/reducers/playerReducer';

const ConfirmPlayingModal: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { trackToConfirm } = useAppSelector((state) => ({
    trackToConfirm: state[REDUCER_KEY_PLAYER].trackToConfirm,
  }));

  const handlePlay = async () => {
    await dispatch(setPlayingTrack({ trackId: trackToConfirm }));
    await dispatch(setPlay({ isPlaying: true }));
    dispatch(confirmPlaying({ trackId: '' }));
  };

  const handleClose = () => {
    dispatch(confirmPlaying({ trackId: '' }));
  };
  return (
    <>
      <ModalWindow
        modalIsOpen={!!trackToConfirm}
        onCloseModal={handleClose}
        size="middle"
      >
        <ModalTitle title="The profile is currently in use" />
        <p className="modal__text">
          The profile is currently in use, continuing this action will cause the
          current session to stop. Do you want to continue?
        </p>

        <div className="row">
          <div className="col-6 col-md-auto">
            <Button type="button" color="primary" onClick={handlePlay}>
              Play
            </Button>
          </div>
          <div className="col-6 col-md-auto">
            <Button type="button" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </ModalWindow>
    </>
  );
};

export default ConfirmPlayingModal;
