import React from 'react';
import classNames from 'classnames';
import Icon from '../Icons';

interface IPlayerButton {
  icon?: string;
  label?: string;
  onClick? (event: React.ChangeEvent<any>): void;
  className?: string;
  children?: React.ReactNode;
  containerClassName?: string;
  active?: boolean;
  disabled?: boolean;
}

const PlayerButton: React.FunctionComponent<IPlayerButton> = ({
  icon,
  label,
  onClick,
  className,
  children,
  containerClassName,
  active,
  disabled,
}) => (
  <div className={classNames('music-player__button-container', {
    [`${containerClassName}`]: containerClassName,
  })}
  >
    {
      onClick
      && (
        <button
          className={classNames('music-player__button', {
            [`${className}`]: classNames,
            'music-player__button--active': active,
          })}
          type="button"
          onClick={onClick}
          disabled={disabled}
        >
          {
            icon
            && (
              <Icon
                name={icon}
                active={active}
              />
            )
          }
        </button>
      )
    }
    {children}
    {
      label
      && (
        <span className="music-player__label">{label}</span>
      )
    }
  </div>
);

export default PlayerButton;
