import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_GENRES,
  ACTION_NAME_FETCH_GENRES,
  ACTION_NAME_FETCH_FAVOURITE_GENRES,
  ACTION_NAME_UPDATE_FAVOURITE_GENRES,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import { IGenres } from '../../types';

interface IArtistsState {
  isFetching: boolean;
  genres: Array<IGenres>;
  favoritesGenres: { likes: Array<IGenres>, dislikes: Array<IGenres> };
}

const initialState: IArtistsState = {
  isFetching: false,
  genres: [],
  favoritesGenres: { likes: [], dislikes: [] },
};

const fetchGenres = createAsyncThunk<AxiosResponse<Array<IGenres>>>(
  `${REDUCER_KEY_GENRES}/${ACTION_NAME_FETCH_GENRES}`,
  async () => {
    const response = await ApiClient.fetchGenres();
    return response;
  },
);

const fetchFavouriteGenres = createAsyncThunk<AxiosResponse<any>, { id: string }>(
  `${REDUCER_KEY_GENRES}/${ACTION_NAME_FETCH_FAVOURITE_GENRES}`,
  async ({
    id,
  }) => {
    const response = await ApiClient.fetchFavouriteGenres(id);
    return response;
  },
);

const updateFavouriteGenres = createAsyncThunk<AxiosResponse<Array<IGenres>>, {
  id: any,
  action: string,
  genres: Array<string>,
}>(
  `${REDUCER_KEY_GENRES}/${ACTION_NAME_UPDATE_FAVOURITE_GENRES}`,
  async ({
    id,
    action,
    genres,
  }) => {
    const response = await ApiClient.updateFavouriteGenres(id, action, genres);
    return response;
  },
);

const genresSlice = createSlice<IArtistsState, SliceCaseReducers<IArtistsState>,
  typeof REDUCER_KEY_GENRES>({
    name: REDUCER_KEY_GENRES,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchGenres.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchGenres.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchGenres.fulfilled, (state, { payload }) => {
          state.genres = payload.data;
          state.isFetching = false;
        })
        .addCase(fetchFavouriteGenres.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchFavouriteGenres.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchFavouriteGenres.fulfilled, (state, { payload }) => {
          state.favoritesGenres = payload.data;
          state.isFetching = false;
        })
        .addCase(updateFavouriteGenres.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(updateFavouriteGenres.fulfilled, (state, { payload }) => {
          state.isFetching = false;
        });
    },
  });

export {
  fetchGenres,
  fetchFavouriteGenres,
  updateFavouriteGenres,
};

export default genresSlice.reducer;
