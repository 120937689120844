import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

enum EButtonType {
  DEFAULT = 'default',
  RELAX = 'relax',
  REMINISCE = 'reminisce',
  ENERGISE = 'energise',
}

interface IButton {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  href?: string;
  to?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  color?: 'primary' | 'secondary' | 'accent';
  view?: EButtonType;
  size?: 'small' | 'middle' | 'minimal';
  showLoader?: boolean;
  onClick? (): void;
  id?: string;
  fullWidth?: boolean;
}

const Container: React.FunctionComponent<IButton> = ({
  children,
  className,
  disabled,
  href,
  to,
  type,
  id,
  onClick,
}) => {
  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" className={className} id={id}>
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} className={className} id={id}>
        {children}
      </Link>
    );
  }

  return (
    <button id={id} onClick={onClick} type={type} disabled={disabled} className={className}>
      {children}
    </button>
  );
};

const Button: React.FunctionComponent<IButton> = ({
  children,
  className,
  disabled,
  href,
  to,
  type,
  view,
  color,
  onClick,
  size,
  showLoader,
  id,
  fullWidth,
}) => {
  const attr = {
    id,
    className,
    href,
    to,
    type,
    disabled,
    onClick,
  };

  return (
    <Container
      {...attr}
      className={classNames('btn', {
        [`btn--${color}`]: color,
        [`btn--${view}`]: view,
        [`btn--${size}`]: size,
        'btn--full-width': fullWidth,
        [`${className}`]: className,
      })}
      disabled={disabled || showLoader}
    >
      {children}
    </Container>
  );
};

export {
  Button as default,
  EButtonType,
};
