import moment from 'moment';
import { IOption } from './components/Select';
import { SECONDS_IN_MINUTE, SECONDS_IN_HOUR, AVAILABLE_LOCATIONS } from './constants';
import { REACT_APP_FPS_CERT_URL, REACT_APP_PALLYCON_SITE_ID } from './config';
import { ICountries, ILanguages, IMembershipDetails } from './types';

export const getNumbrOfMonthDays = (
  month: number,
  year: number,
) => new Date(year, month + 1, 0).getDate();

export const getTimeFromSeconds = (seconds: number, isTimer?: boolean) => {
  const hours = Math.floor(seconds / SECONDS_IN_HOUR);
  const minutes = Math.floor((seconds - hours * SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
  const sec = (seconds - Math.floor(seconds / SECONDS_IN_MINUTE) * SECONDS_IN_MINUTE).toFixed(0);
  const hh = hours > 0 ? `${hours}h ` : '';
  const mm = minutes > 0 ? `${minutes}m ` : '';
  const ss = parseInt(sec, 10) > 0 ? `${sec}s` : '0s';
  const formatted = moment.utc(seconds * 1000).format('mm:ss');
  return isTimer ? formatted : `${hh}${mm}${ss}`;
};

export const mapOptionsToValues = (loadedOptions: Array<{
  id: string,
  main_genre: string,
  name: string,
}>) => loadedOptions.map((item: {
  id: string, name: string, main_genre: string,
}) => ({
  value: item.id, label: item.name, main_genre: item.main_genre,
}));

export const base64DecodeUint8Array = (input: any) => {
  const raw = window.atob(input);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i += 1) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
};

export const getFairplayCert = () => {
  const fpsCertUrl = `${REACT_APP_FPS_CERT_URL}?siteId=${REACT_APP_PALLYCON_SITE_ID}`;
  const xmlhttp = new XMLHttpRequest();

  xmlhttp.open('GET', `${fpsCertUrl}`, false);
  xmlhttp.send();
  const fpsCert = base64DecodeUint8Array(xmlhttp.responseText);
  return fpsCert;
};

export const durationToSeconds = (duration: string) => {
  const seconds = moment.duration(duration).asSeconds();
  return seconds;
};

export const secondsToString = (seconds: number) => {
  const hours = moment.utc(seconds * 1000).hours();
  const minutes = moment.utc(seconds * 1000).minutes();
  return `${hours} hours ${minutes} mins`;
};

export const isIpadOS = () => navigator.maxTouchPoints
    && navigator.maxTouchPoints > 2
    && /MacIntel/.test(navigator.platform);

export const filterLanguages = (languages: ILanguages[]) => {
  const languagesArray: IOption[] = languages?.map(
    (element) => ({
      label: element.name,
      value: element.code,
    }),
  ).sort((a, b) => a.label.localeCompare(b.label));

  const filteredLanguages = languagesArray?.filter(
    (el) => el.value === 'en',
  ).concat(languagesArray.filter(
    (el) => el.value !== 'en',
  ));

  return filteredLanguages;
};

export const mapCountries = (countries: ICountries[]) => {
  const countriesArray: IOption[] = countries?.map(
    (element) => ({
      label: element.name,
      value: element.code,
    }),
  );
  return countriesArray;
};

export const filterCountries = (countries: ICountries[]) => {
  const countriesArray: IOption[] = mapCountries(countries);
  const filteredCountries = countriesArray?.filter(
    (el) => {
      const { value } = el;
      return AVAILABLE_LOCATIONS.includes(value as string);
    },
  ).concat(countriesArray?.filter(
    (el) => {
      const { value } = el;
      return !AVAILABLE_LOCATIONS.includes(value as string);
    },
  ));

  return filteredCountries;
};

export const sameDay = (date: Date | undefined) => {
  if (date !== undefined) {
    return moment(date).isSame(Date.now(), 'day');
  }
  return false;
};

export const getTimeDifference = (from: Date, to: Date) => {
  const fromSeconds = from.getTime() / 1000;
  const toSeconds = to.getTime() / 1000;
  return toSeconds - fromSeconds;
};

export const parseBillingDate = (membershipDetails: IMembershipDetails) => {
  const month = moment(membershipDetails.next_billing_date).format('MMMM');
  const day = moment(membershipDetails.next_billing_date).format('DD');
  const year = moment(membershipDetails.next_billing_date).format('YYYY');
  const currency = membershipDetails.subscription?.subscription_currency?.toUpperCase();
  const price = membershipDetails.subscription?.subscription_price;
  return `${month} ${day}, ${year} for ${currency} ${price}`;
};

export const currencyFunction = (current_location: string | undefined) => {
  const currencyArray = [];
  switch (current_location) {
    case 'GB':
      currencyArray.push('GBP', '£');
      break;
    case 'US':
      currencyArray.push('USD', '$');
      break;
    case 'CA':
      currencyArray.push('CAD', '$');
      break;
    case 'NZ':
      currencyArray.push('NZD', '$');
      break;
    default:
      currencyArray.push('AUD', '$');
      break;
  }
  return currencyArray;
};
