import React from 'react';
import ModalWindow, { ModalTitle } from './index';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearLicenseError } from '../../store/reducers/playerReducer';
import { REDUCER_KEY_PLAYER } from '../../constants';

const SessionExpiredModal: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { licenseTokenError } = useAppSelector((state) => ({
    licenseTokenError: state?.[REDUCER_KEY_PLAYER]?.licenseTokenError,
  }));

  const handleClose = () => {
    dispatch(clearLicenseError({}));
  };

  return (
    <ModalWindow
      modalIsOpen={!!licenseTokenError}
      onCloseModal={handleClose}
      size="middle"
    >
      <ModalTitle title={licenseTokenError} />
      <p className="modal__text">
        Your session has completed as the profile is being used on another
        device.
      </p>
    </ModalWindow>
  );
};

export default SessionExpiredModal;
