import persistStorage from 'redux-persist/lib/storage';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import { REDUCER_KEY_APP_SETTINGS } from '../../constants';

interface IAppSettingsState {
  isTrialReminderWasShown: boolean;
}

const initialState: IAppSettingsState = {
  isTrialReminderWasShown: false,
};

const appSettingsSlice = createSlice<
  IAppSettingsState,
  SliceCaseReducers<IAppSettingsState>,
  typeof REDUCER_KEY_APP_SETTINGS
>({
  name: REDUCER_KEY_APP_SETTINGS,
  initialState,
  reducers: {
    setTrialReminderWasShown: (state) => {
      state.isTrialReminderWasShown = !state.isTrialReminderWasShown;
    },
  },
});

export const { setTrialReminderWasShown } = appSettingsSlice.actions;

const persistConfig = {
  key: REDUCER_KEY_APP_SETTINGS,
  storage: persistStorage,
  whitelist: ['isTrialReminderWasShown'],
};

const appSettingsReducer = persistReducer(
  persistConfig,
  appSettingsSlice.reducer,
);

export default appSettingsReducer;
