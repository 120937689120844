import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import {
  REDUCER_KEY_ACTIVITY,
  REDUCER_KEY_ARTISTS,
  REDUCER_KEY_AUTH,
  REDUCER_KEY_CHAT,
  REDUCER_KEY_COMMON,
  REDUCER_KEY_COUNTRIES,
  REDUCER_KEY_FACILITY,
  REDUCER_KEY_GENRES,
  REDUCER_KEY_LANGUAGES,
  REDUCER_KEY_MODAL,
  REDUCER_KEY_PLAYER,
  REDUCER_KEY_PROFILE,
  REDUCER_KEY_SETTINGS,
  REDUCER_KEY_STRIPE,
  REDUCER_KEY_TRACKS,
  REDUCER_KEY_APP_SETTINGS,
} from '../constants';
import activityReducer from './reducers/activityReducer';
import appSettingsReducer from './reducers/appSettingsReducer';
import artistsReducer from './reducers/artistsReducer';
import authReducer from './reducers/authReducer';
import chatReducer from './reducers/chatReducer';
import commonReducer from './reducers/commonReducer';
import countriesReducer from './reducers/countriesReducer';
import facilityReducer from './reducers/facilityReducer';
import genresReducer from './reducers/genresReducer';
import languagesReducer from './reducers/languagesReducer';
import modalReducer from './reducers/modalReducer';
import playerReducer from './reducers/playerReducer';
import profileReducer from './reducers/profileReducer';
import settingsReducer from './reducers/settingsReducer';
import stripeReducer from './reducers/stripeReducer';
import tracksReducer from './reducers/tracksReducer';

const initialState = {};

const store = configureStore({
  reducer: {
    [REDUCER_KEY_AUTH]: authReducer,
    [REDUCER_KEY_PROFILE]: profileReducer,
    [REDUCER_KEY_COMMON]: commonReducer,
    [REDUCER_KEY_ARTISTS]: artistsReducer,
    [REDUCER_KEY_GENRES]: genresReducer,
    [REDUCER_KEY_PLAYER]: playerReducer,
    [REDUCER_KEY_TRACKS]: tracksReducer,
    [REDUCER_KEY_LANGUAGES]: languagesReducer,
    [REDUCER_KEY_COUNTRIES]: countriesReducer,
    [REDUCER_KEY_STRIPE]: stripeReducer,
    [REDUCER_KEY_MODAL]: modalReducer,
    [REDUCER_KEY_SETTINGS]: settingsReducer,
    [REDUCER_KEY_CHAT]: chatReducer,
    [REDUCER_KEY_ACTIVITY]: activityReducer,
    [REDUCER_KEY_FACILITY]: facilityReducer,
    [REDUCER_KEY_APP_SETTINGS]: appSettingsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  preloadedState: initialState,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const persistor = persistStore(store);

export { store as default, persistor };
