import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import {
  REDUCER_KEY_MODAL,
} from '../../constants';

interface IModalState {
  isOpen: boolean;
  onClose?(): void;
}

const initialState: IModalState = {
  isOpen: false,
  onClose: undefined,
};

const modalSlice = createSlice<IModalState,
  SliceCaseReducers<IModalState>,
  typeof REDUCER_KEY_MODAL>({
    name: REDUCER_KEY_MODAL,
    initialState,
    reducers: {
      setOpen: (state, action: PayloadAction<{ isOpen: boolean }>) => {
        state.isOpen = action.payload.isOpen;
      },
    },
  });

const {
  setOpen,
} = modalSlice.actions;

export {
  setOpen,
};

export default modalSlice.reducer;
