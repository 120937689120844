import React from 'react';
import { Redirect } from 'react-router-dom';
import ROUTES from './constants';
import { useAppSelector } from '../store/hooks';
import { REDUCER_KEY_AUTH } from '../constants';

const ProRedirectRoute: React.FunctionComponent = () => {
  const { isGuest } = useAppSelector((state) => ({
    isGuest: state[REDUCER_KEY_AUTH].isGuest,
  }));

  if (isGuest) {
    return <Redirect to={ROUTES.LOGIN_PRO} />;
  }

  return <Redirect to={ROUTES.HOME} />;
};

export default ProRedirectRoute;
