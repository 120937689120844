import React from 'react';
import ReactModal, { OnAfterOpenCallback } from 'react-modal';
import classNames from 'classnames';
import Icon, { ICON_NAMES } from '../Icons';

interface IModalWindow {
  modalIsOpen: boolean;
  onCloseModal?(event: React.MouseEvent | React.KeyboardEvent): void;
  afterOpenModal?: OnAfterOpenCallback,
  children?: React.ReactNode,
  size?: 'extra-wide' | 'wide' | 'middle' | 'small' | 'xs',
  className?: string,
}

const defaultStyles = {
  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    zIndex: 101,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    marginRight: 0,
    border: 'none',
    background: '#ffffff',
    borderRadius: '10px',
  },
};

const ModalTitle: React.FunctionComponent<{title: string}> = ({
  title,
}) => (
  <div className="modal__title">
    {title}
  </div>
);

const ModalWindow: React.FunctionComponent<IModalWindow> = ({
  modalIsOpen,
  onCloseModal,
  afterOpenModal,
  children,
  size,
  className,
}) => (
  <ReactModal
    isOpen={modalIsOpen}
    onAfterOpen={afterOpenModal}
    onRequestClose={onCloseModal}
    style={defaultStyles}
    contentLabel="Example Modal"
    className={classNames('modal', {
      [`modal--${size}`]: size,
      [`modal--${className}`]: className,
    })}
    ariaHideApp={false}
  >
    <button
      type="button"
      onClick={onCloseModal}
      className="modal__close-btn"
    >
      <Icon
        name={ICON_NAMES.CLOSE}
        className="modal__close-btn-icon"
      />
      Close
    </button>
    {children}
  </ReactModal>
);

export default ModalWindow;
export {
  ModalTitle,
};
