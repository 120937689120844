import React from 'react';

interface IOverlayPlayerBanner {
  title: string;
  text: string;
  image?: string;
  children?: React.ReactNode;
  className?: string;
}

const OverlayPlayerBanner: React.FunctionComponent<IOverlayPlayerBanner> = ({
  title,
  text,
  image,
  children,
  className,
}) => (
  <div className="greeting-banner-container">
    <div className="greeting-banner">
      <div className="greeting-banner__content">
        <div className="greeting-banner__title">{title}</div>
        <div className={`greeting-banner__text ${className}`}>{text}</div>
        {children}
      </div>
      {
        image
        && (
          <div className="greeting-banner__image-container">
            <img src={image} alt={title} title={title} className="greeting-banner__image" />
          </div>
        )
      }
    </div>
  </div>
);

export default OverlayPlayerBanner;
