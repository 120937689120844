import React from 'react';
import classNames from 'classnames';
import Icon, { ICON_NAMES } from '../Icons';
import { useAppSelector } from '../../store/hooks';
import { REDUCER_KEY_AUTH } from '../../constants';
import usePro from '../../hooks/usePro';

const Logo: React.FunctionComponent<{ [key: string]: any }> = (props: any) => {
  const { isGuest } = useAppSelector((state) => ({
    isGuest: state[REDUCER_KEY_AUTH].isGuest,
  }));

  const { isPro: isProProps } = props;

  const { isPro } = usePro();

  return (
    <div
      className={classNames('logo-container', {
        'logo-container--pro': isPro,
        'logo-container--pro-left-bar': (isPro || isProProps) && !isGuest,
      })}
    >
      <Icon
        name={
          isPro || isProProps
            ? isGuest
              ? ICON_NAMES.LOGO_PRO
              : ICON_NAMES.LOGO_PRO_LEFT_BAR
            : ICON_NAMES.LOGO
        }
        className={classNames('vera-logo', {
          'vera-logo--pro': isPro || isProProps,
        })}
      />
    </div>
  );
};

export default Logo;
