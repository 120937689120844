import React from 'react';
import moment from 'moment';
import Button from '../Button';
import EntityCover from '../EntityCover';

interface IResidentCard {
  id: string;
  human_id: string;
  invite_id?: string;
  name: string;
  photo: string | undefined;
  last_played: Date | string;
  onClick?(residentId: string): void;
  isListening?: boolean;
}

const ResidentCard: React.FunctionComponent<IResidentCard> = ({
  id,
  human_id,
  invite_id,
  name,
  photo,
  last_played,
  onClick,
  isListening = false,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Button className="resident-card btn--resident-card" onClick={handleClick}>
      <div className="resident-card__photo-container">
        <EntityCover
          title={name}
          src={photo}
          rounded
          size="big"
          className="resident-card__photo-image"
          disabled={!!invite_id}
        />
      </div>
      <div className="resident-card__data">
        <div className="resident-card__name">{name}</div>
        <div className="resident-card__res-id">
          <span className="resident-card__res-id-label">Resident ID : </span>
          <span className="resident-card__res-id-value">{human_id}</span>
        </div>
        {isListening ? (
          <div className="resident-card__played">
            <span className="resident-card__played-value">Listening</span>
          </div>
        ) : (
          <div className="resident-card__played">
            <span className="resident-card__played-label">Last played on </span>
            <span className="resident-card__played-value">
              {moment(last_played).format('DD/MM/YYYY')}
            </span>
          </div>
        )}
      </div>
    </Button>
  );
};

export default ResidentCard;
