import { useMemo, useState } from 'react';
import { useAppSelector } from '../store/hooks';
import { REDUCER_KEY_AUTH, REDUCER_KEY_PROFILE } from '../constants';
import { IProfile } from '../types';

const useProfileData = () => {
  const { listenerMode, profile, residentData } = useAppSelector((state) => ({
    listenerMode: state[REDUCER_KEY_AUTH].listenerMode,
    profile: state[REDUCER_KEY_PROFILE].profiles[0],
    residentData: state[REDUCER_KEY_PROFILE].activeResident,
  }));

  const profileData = useMemo(() => {
    if (listenerMode && residentData?.id) {
      return residentData;
    }
    if (!listenerMode && profile?.id) {
      return profile;
    }
    return profile;
  }, [listenerMode, profile, residentData]);

  return { profileData };
};

export default useProfileData;
