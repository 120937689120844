import React from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import ROUTES from './constants';
import { useAppSelector } from '../store/hooks';
import { REDUCER_KEY_AUTH } from '../constants';
import GuestLayout from '../layout/GuestLayout';

const GuestRoute: React.FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const history = useHistory();
  const { auth } = useAppSelector((state) => ({ auth: state[REDUCER_KEY_AUTH] }));
  const { search } = history.location;
  const token = search.substring(search.indexOf('?token=') + 7);

  if (!Component) return null;

  if (auth.isGuest || auth.isRegistering) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <GuestLayout>
            <Component {...props} />
          </GuestLayout>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        token
          ? <Redirect to={`${ROUTES.VERIFY_EMAIL}/?token=${token}`} />
          : <Redirect to={ROUTES.HOME} />
      )}
    />
  );
};

export default GuestRoute;
