import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import ModalWindow, { ModalTitle } from './index';
import ResidentCard from '../ResidentCard';
import InputSearch from '../Input/InputSearch';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { REDUCER_KEY_PROFILE } from '../../constants';
import { IProfile } from '../../types';
import { fetchProfiles } from '../../store/reducers/profileReducer';

interface IResidentsModal {
  modalIsOpen: boolean;
  toggleModalOpen(): void;
  onSelectResident(residentId: string): void;
}

const ResidentsModal: React.FunctionComponent<IResidentsModal> = ({
  modalIsOpen,
  toggleModalOpen,
  onSelectResident,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredList, setFilteredList] = useState<IProfile[]>();
  const dispatch = useAppDispatch();

  const {
    profilesList,
  } = useAppSelector((state) => ({
    profilesList: state[REDUCER_KEY_PROFILE].profiles,
  }));

  const handleSelectResident = useCallback((residentId: string) => {
    onSelectResident(residentId);
  }, [onSelectResident]);

  const handleSearchChange = (event: React.FormEvent<HTMLInputElement>) => {
    // @ts-ignore
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (searchValue !== '') {
      // @ts-ignore
      const filteredProfiles = profilesList?.filter((item) => item.account_name
        .toLowerCase()
        .includes(searchValue.toLowerCase()));
      setFilteredList(filteredProfiles);
    } else {
      setFilteredList(profilesList);
    }
    // eslint-disable-next-line
  }, [searchValue, profilesList]);

  useEffect(() => {
    dispatch(fetchProfiles());
    // eslint-disable-next-line
  }, []);

  return (
    <ModalWindow
      modalIsOpen={modalIsOpen}
      onCloseModal={toggleModalOpen}
      size="extra-wide"
    >
      <ModalTitle
        // @ts-ignore
        title="Select the resident"
      />
      <p className="modal__text">Choose a resident you want to play the music for</p>
      <div className="search-resident-container">
        <InputSearch
          name="search"
          value={searchValue}
          onChange={handleSearchChange}
          placeholder="Search resident"
        />
      </div>
      <div className="residents-list">
        <div className="row">
          {
            filteredList
            && filteredList.map((residentItem) => (
              <div className="col-md-3" key={residentItem.id}>
                <ResidentCard
                  id={`${residentItem.id}`}
                  human_id={`${residentItem.human_id}`}
                  name={`${residentItem.account_name}`}
                  photo={residentItem.photo}
                  last_played={`${residentItem.created_at}`}
                  onClick={handleSelectResident}
                  invite_id={residentItem.invite_id}
                  // isListening={residentItem.listening}
                  isListening={false}
                />
              </div>
            ))
          }
        </div>
      </div>
    </ModalWindow>
  );
};

export default ResidentsModal;
