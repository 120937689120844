import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { setOpen } from '../store/reducers/modalReducer';

const initialConfig = {
  timeout: 10000,
};

function handleRequest(config: AxiosRequestConfig) {
  return config;
}

function handleResponse(response: AxiosResponse, store: any) {
  const { message } = response.data;

  if (response.status < 400) {
    return response;
  }

  return Promise.reject(new Error(message || 'Something went wrong'));
}

const handleResponseError = (error: any, store: any) => {
  console.error('LOG---', error);
  let message = '';

  if (error.response) {
    if (error.response.status === 500) {
      store.dispatch(setOpen({ isOpen: true }));
      return null;
    }
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.warn(
      error.response.config.url,
      error.response.status,
      // error.response.data,
      // error.response.request?._headers,
    );

    // TODO display the error message
    // if (error.response.status === 500) {
    //   store.dispatch(setOpen({isOpen: true}));
    // }
    const keys = Object.keys(error.response.data);
    const key = keys[0];
    message = error.response.data[key];
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.warn(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.warn('Error', error.message);
  }

  // if time out
  if (error.code === 'ECONNABORTED') {
    store.dispatch(setOpen({ isOpen: true }));
  }

  return Promise.reject(new Error(message || 'Something went wrong'));
};

export {
  initialConfig as default,
  handleRequest,
  handleResponse,
  handleResponseError,
};
