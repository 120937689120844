import {
  createAsyncThunk,
  createSlice,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  ACTION_NAME_FETCH_TRACKS_PREFERENCES, ACTION_NAME_RATE_MUSIC,
  ACTION_NAME_UPDATE_TRACKS_PREFERENCES,
  REDUCER_KEY_TRACKS,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import { IRate, ITrack } from '../../types';

interface ITracksState {
  isFetching: boolean;
  tracksPreferences: { likes: Array<ITrack>, dislikes: Array<ITrack> };
}

const initialState: ITracksState = {
  isFetching: false,
  tracksPreferences: { likes: [], dislikes: [] },
};

const fetchTracksPreferences = createAsyncThunk<AxiosResponse<any>, { id: string }>(
  `${REDUCER_KEY_TRACKS}/${ACTION_NAME_FETCH_TRACKS_PREFERENCES}`,
  async ({
    id,
  }) => {
    const response = await ApiClient.fetchTracksPreferences(id);
    return response;
  },
);

const updateTracksPreferences = createAsyncThunk<AxiosResponse<any>, {
  id: string,
  action: string,
  tracks: Array<string>,
  comment?: string,
}>(
  `${REDUCER_KEY_TRACKS}/${ACTION_NAME_UPDATE_TRACKS_PREFERENCES}`,
  async ({
    id,
    action,
    tracks,
    comment,
  }) => {
    const response = await ApiClient.updateTracksPreferences(id, action, tracks, comment);
    return response;
  },
);

const rateMusic = createAsyncThunk<AxiosResponse<any>, { profile_id: string, rateObject: IRate }>(
  `${REDUCER_KEY_TRACKS}/${ACTION_NAME_RATE_MUSIC}`,
  async ({
    profile_id,
    rateObject,
  }) => {
    const response = await ApiClient.addFeedback(profile_id, rateObject);
    return response;
  },
);

const tracksSlice = createSlice<ITracksState,
  SliceCaseReducers<ITracksState>,
  typeof REDUCER_KEY_TRACKS>({
    name: REDUCER_KEY_TRACKS,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchTracksPreferences.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchTracksPreferences.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchTracksPreferences.fulfilled, (state, { payload }) => {
          state.tracksPreferences = payload.data;
          state.isFetching = false;
        })
        .addCase(updateTracksPreferences.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(updateTracksPreferences.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(updateTracksPreferences.fulfilled, (state, { payload }) => {
          state.tracksPreferences = payload.data;
          state.isFetching = false;
        });
    },
  });

export {
  fetchTracksPreferences,
  updateTracksPreferences,
  rateMusic,
};

export default tracksSlice.reducer;
