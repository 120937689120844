import React from 'react';
import Icons, { ICON_NAMES } from '../Icons';

interface ISwitchMenuButton {
  text: string;
  onSwitchMode(): void;
}

const SwitchMenuButton: React.FunctionComponent<ISwitchMenuButton> = ({
  text,
  onSwitchMode,
}) => (
  <button
    className="main-menu__button"
    onClick={onSwitchMode}
    type="button"
  >
    <span className="main-menu__button-mode">{text}</span>
    <span className="main-menu__button-container">
      <Icons name={ICON_NAMES.SWITCH} className="main-menu__button-icon" />
      <span className="main-menu__button-text">Switch</span>
    </span>
  </button>
);

export default SwitchMenuButton;
