import React from 'react';
import classNames from 'classnames';

const Preloader: React.FunctionComponent<{ small?: boolean, notTransparent?: boolean }> = ({
  small,
  notTransparent,
}) => (
  <span
    className={classNames('preloader-overlay', {
      'preloader-overlay--flat': notTransparent,
    })}
  >
    <span
      className={classNames('preloader-spinner', {
        'preloader-spinner--small': small,
      })}
    >
      <span className="preloader-spinner-dot preloader-spinner-dot--dot-1" />
      <span className="preloader-spinner-dot preloader-spinner-dot--dot-2" />
      <span className="preloader-spinner-dot preloader-spinner-dot--dot-3" />
      <span className="preloader-spinner-dot preloader-spinner-dot--dot-4" />
      <span className="preloader-spinner-dot preloader-spinner-dot--dot-5" />
    </span>
  </span>
);

export default Preloader;
