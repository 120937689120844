import { useAppSelector } from '../store/hooks';
import { REDUCER_KEY_COMMON } from '../constants';

const useCommonState = () => {
  const { common } = useAppSelector((state) => ({ common: state[REDUCER_KEY_COMMON] }));
  const isMobile = common.windowWidth < 768;
  const isTablet = common.windowWidth < 1200;

  return {
    isMobile,
    isTablet,
  };
};

export default useCommonState;
