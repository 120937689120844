import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { persistReducer } from 'redux-persist';
import persistStorage from 'redux-persist/lib/storage';
import {
  ACTION_NAME_APPLE_LOGIN_USER,
  ACTION_NAME_FACEBOOK_LOGIN_USER,
  ACTION_NAME_FORGOT_PASSWORD_USER,
  ACTION_NAME_GET_COUNTRY_BY_IP,
  ACTION_NAME_GOOGLE_LOGIN_USER,
  ACTION_NAME_GUEST_ADMIN_SET_PASSWORD,
  ACTION_NAME_LOGIN_USER,
  ACTION_NAME_LOGIN_USER_PRO,
  ACTION_NAME_LOGOUT_USER,
  ACTION_NAME_NOTIFY_USER,
  ACTION_NAME_REFRESH_USER_TOKEN,
  ACTION_NAME_RESET_PASSWORD_USER,
  ACTION_NAME_SET_MEMBER_PASSWORD,
  ACTION_NAME_SIGNUP_USER,
  ACTION_NAME_SIGNUP_USER_PRO,
  ACTION_NAME_VERIFY_EMAIL,
  ACTION_NAME_VERIFY_EMAIL_REQUIRING,
  REDUCER_KEY_AUTH,
  REDUCER_KEY_FACILITY,
  USER_TYPE,
  VERA_TYPE,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import { RootState } from '../index';
import { fetchProUserProfileData } from './profileReducer';
import { fetchFacilityData } from './facilityReducer';

/* eslint-disable no-param-reassign */

interface AuthState {
  isGuest: boolean;
  isFetching: boolean;
  userData: Record<any, unknown>;
  token: string,
  refreshToken: string,
  account_id: string,
  showHowAreYouModal: boolean,
  isRegistering: boolean;
  isFetchingVerifying: boolean;
  email_verified: string;
  listenerMode: boolean;
  veraType: VERA_TYPE;
  location: string;
  userType: USER_TYPE;
  resident_id: string;
  ipCountry: string;
}

interface LoginUserParams {
  email: string;
  password: string;
}

interface RefreshUserParams {
  refreshToken: string;
}

interface SignupUserParams {
  email: string;
  password: string;
  name: string;
}

interface ForgotPasswordParams {
  email: string;
}

interface ResetPasswordParams {
  password: string,
  confirm_password: string,
}

interface NotifyParams {
  email: string,
  country: string,
}

const initialState: AuthState = {
  isGuest: true,
  isFetching: false,
  userData: {},
  token: '',
  refreshToken: '',
  account_id: '',
  isRegistering: false,
  isFetchingVerifying: false,
  email_verified: '',
  showHowAreYouModal: false,
  listenerMode: false,
  veraType: VERA_TYPE.UNSELECTED,
  location: '',
  userType: USER_TYPE.UNSELECTED,
  resident_id: '',
  ipCountry: '',
};

interface ISignUpResponse {
  id: string;
  name: string;
  email: string;
  access: string;
  refresh: string;
  ip_country: string;
}

const signupUser = createAsyncThunk<AxiosResponse<ISignUpResponse>, SignupUserParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_SIGNUP_USER}`,
  async ({
    email,
    password,
    name,
    // eslint-disable-next-line arrow-body-style
  }) => {
    await ApiClient.signUp({
      id: '',
      email,
      password,
      name,
    });
    const responseLogin = await ApiClient.logIn({
      email,
      password,
    });
    ApiClient.setToken(responseLogin.data.access);
    await ApiClient.createProfile({});
    return responseLogin;
  },
);

const signupUserPro = createAsyncThunk<AxiosResponse<ISignUpResponse>, SignupUserParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_SIGNUP_USER_PRO}`,
  async ({
    email,
    password,
    name,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const responseSignUp = await ApiClient.signUpPro({
      email,
      password,
      name,
    });
    if (responseSignUp.status >= 200 && responseSignUp.status < 300) {
      const responseLogin = await ApiClient.logInPro({
        email,
        password,
      });
      ApiClient.setToken(responseLogin.data.access);
      return responseLogin;
    }
    return responseSignUp;
  },
);

interface ILoginResponse {
  access: string;
  refresh: string;
  ip_coutnry: string;
  user_type: USER_TYPE;
}

const loginUser = createAsyncThunk<AxiosResponse<ILoginResponse>,
  LoginUserParams,
  { state: RootState }>(
    `${REDUCER_KEY_AUTH}/${ACTION_NAME_LOGIN_USER}`,
    async ({
      email,
      password,
      // eslint-disable-next-line arrow-body-style
    }, { dispatch }) => {
      const response = await ApiClient.logIn({ email, password });
      ApiClient.setToken(response.data.access);
      if (response.data.user_type !== USER_TYPE.FACILITY_MEMBER) {
        const getProfile = await ApiClient.fetchProfiles();
        if (!getProfile.data.length) await ApiClient.createProfile({});
      }
      if (response.data.user_type === USER_TYPE.FACILITY_MEMBER) {
        const responseProUser = await dispatch(fetchProUserProfileData());
        await dispatch(fetchFacilityData({
          // @ts-ignore
          facility_id: responseProUser.payload.data.facility_id,
        }));
      }
      return response;
    },
  );

const loginUserPro = createAsyncThunk<AxiosResponse<ILoginResponse>,
  LoginUserParams,
  { state: RootState }>(
    `${REDUCER_KEY_AUTH}/${ACTION_NAME_LOGIN_USER_PRO}`,
    async ({
      email,
      password,
      // eslint-disable-next-line arrow-body-style
    }, { dispatch }) => {
      const response = await ApiClient.logInPro({ email, password });
      ApiClient.setToken(response.data.access);
      const responseProUser = await dispatch(fetchProUserProfileData());
      await dispatch(fetchFacilityData({
        // @ts-ignore
        facility_id: responseProUser.payload.data.facility_id,
      }));
      return response;
    },
  );

interface ISocialLoginUserParams {
  access_token: string;
}

interface ISocialAppleLoginUserParams {
  code: string;
  id_token: string;
  name: string;
}
interface ISocialLoginResponse {
  access_token: string;
  refresh_token: string;
}

const googleLogin = createAsyncThunk<AxiosResponse<ISocialLoginResponse>, ISocialLoginUserParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_GOOGLE_LOGIN_USER}`,
  async ({
    access_token,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.googleLogin(access_token);
    ApiClient.setToken(response.data.access_token);
    const getProfile = await ApiClient.fetchProfiles();
    if (!getProfile.data.length) await ApiClient.createProfile({});
    return response;
  },
);

const facebookLogin = createAsyncThunk<AxiosResponse<ISocialLoginResponse>, ISocialLoginUserParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_FACEBOOK_LOGIN_USER}`,
  async ({
    access_token,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.facebookLogin(access_token);
    ApiClient.setToken(response.data.access_token);
    const getProfile = await ApiClient.fetchProfiles();
    if (!getProfile.data.length) await ApiClient.createProfile({});
    return response;
  },
);

const appleLogin = createAsyncThunk<
AxiosResponse<ISocialLoginResponse>, ISocialAppleLoginUserParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_APPLE_LOGIN_USER}`,
  async ({
    code,
    id_token,
    name,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.appleLogin(id_token, code, name);
    ApiClient.setToken(response.data.access_token);
    const getProfile = await ApiClient.fetchProfiles();
    if (!getProfile.data.length) {
      await ApiClient.createProfile({});
    }
    return response;
  },
);

const refreshAccessToken = createAsyncThunk<AxiosResponse<ILoginResponse>, RefreshUserParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_REFRESH_USER_TOKEN}`,
  async ({
    refreshToken,
  },
  { dispatch }) => {
    const response = await ApiClient.refreshUserToken(refreshToken);
    if (response.data.access) {
      ApiClient.setToken(response.data.access);
    }
    return response;
  },
);

interface ILogOutResponse {
  message: string;
}

const logoutUser = createAsyncThunk<AxiosResponse<ILogOutResponse>,
  RefreshUserParams>(
    `${REDUCER_KEY_AUTH}/${ACTION_NAME_LOGOUT_USER}`,
    // eslint-disable-next-line arrow-body-style
    async ({
      refreshToken,
    }) => {
      const response = await ApiClient.logout(refreshToken);
      return response;
    },
  );

const forgotPasswordUser = createAsyncThunk<Promise<AxiosResponse<any>>, ForgotPasswordParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_FORGOT_PASSWORD_USER}`,
  async ({
    email,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.forgotPassword(email);
    return response.data;
  },
);

const resetPasswordUser = createAsyncThunk<Promise<AxiosResponse<any>>, ResetPasswordParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_RESET_PASSWORD_USER}`,
  async ({
    password,
    confirm_password,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.resetPassword({ password, confirm_password });
    return response.data;
  },
);

const notifyUser = createAsyncThunk<Promise<AxiosResponse<any>>, NotifyParams>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_NOTIFY_USER}`,
  async ({
    email,
    country,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.notify({ email, country });
    return response.data;
  },
);

const verifyEmailRequiring = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_VERIFY_EMAIL_REQUIRING}`,
  async () => {
    const response = await ApiClient.verifyEmailRequiring();
    return response.data;
  },
);

const verifyEmail = createAsyncThunk<AxiosResponse<any>, { token: string }>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_VERIFY_EMAIL}`,
  async ({
    token,
  }) => {
    const response = await ApiClient.verifyEmail(token);
    return response;
  },
);

const getCurrentLocation = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_GET_COUNTRY_BY_IP}`,
  async () => {
    const response = await ApiClient.getCurrentLocation();
    return response;
  },
);

const loginReducer = (state: any, { payload }: any) => {
  state.userData = { ...payload };
  state.isFetching = false;
  state.isGuest = false;
  state.token = payload.data.access || payload.data.access_token;
  state.refreshToken = payload.data.refresh || payload.data.refresh_token;
  state.isRegistering = false;
  state.showHowAreYouModal = true;
  state.userType = payload.data.user_type;
  state.listenerMode = false;
  state.ipCountry = payload.data.ip_country;
};
const setPasswordGuestAdmin = createAsyncThunk<AxiosResponse<any>, {
  password: string,
  token: string,
  email: string,
  name: string,
}>(
  `${REDUCER_KEY_AUTH}/${ACTION_NAME_GUEST_ADMIN_SET_PASSWORD}`,
  async ({
    password,
    token,
    email,
    name,
  }) => {
    const response = await ApiClient.setPasswordAdminAccount(password, token, email, name);
    const responseLogin = await ApiClient.logIn({
      email: response.data.email,
      password,
    });
    ApiClient.setToken(responseLogin.data.access);
    return responseLogin;
  },
);

const setMemberPassword = createAsyncThunk<
  AxiosResponse<any>,
  {
    facility_id: string,
    member_id: string,
    token: string,
    password: string,
    email: string,
  }
>(
  `${REDUCER_KEY_FACILITY}/${ACTION_NAME_SET_MEMBER_PASSWORD}`,
  async (
    {
      facility_id,
      member_id,
      token,
      password,
      email,
    },
    {
      dispatch,
    },
  ) => {
    const response = await ApiClient.setMemberPassword(
      facility_id,
      member_id,
      token,
      password,
      email,
    );
    const responseLogin = await ApiClient.logInPro({
      email,
      password,
    });
    ApiClient.setToken(responseLogin.data.access);
    return responseLogin;
  },
);

const authSlice = createSlice<AuthState, SliceCaseReducers<AuthState>, typeof REDUCER_KEY_AUTH>({
  name: REDUCER_KEY_AUTH,
  initialState,
  reducers: {
    clearUser: (state) => {
      state.isGuest = true;
      state.isFetching = false;
      state.userData = {};
      state.token = '';
      state.refreshToken = '';
      state.account_id = '';
      state.isRegistering = false;
      state.email_verified = '';
      state.isFetchingVerifying = false;
      state.userType = USER_TYPE.UNSELECTED;
      state.resident_id = '';
      state.listenerMode = false;
    },
    registrationFinish: (state) => {
      state.isGuest = false;
      state.isRegistering = false;
    },
    setIsRegistering: (state, action: PayloadAction<{ isRegistering: boolean }>) => {
      state.isRegistering = action.payload.isRegistering;
    },
    setShowHowAreYouModal: (state, action: PayloadAction<{ isRegistering: boolean }>) => {
      state.showHowAreYouModal = false;
    },
    setListenerMode: (state, action: PayloadAction<{ listenerMode: boolean }>) => {
      state.listenerMode = action.payload.listenerMode;
    },
    setVeraType: (state, action: PayloadAction<{ veraType: VERA_TYPE }>) => {
      state.veraType = action.payload.veraType;
    },
    appendTokens: (state, { payload }: PayloadAction<{ token: string, refreshToken: string }>) => {
      state.token = payload?.token;
      state.refreshToken = payload?.refreshToken;
    },
    setResidentId: (state, { payload }: PayloadAction<{ resident_id: string }>) => {
      state.resident_id = payload.resident_id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(loginUser.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(loginUser.fulfilled, loginReducer)
      .addCase(loginUserPro.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(loginUserPro.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(loginUserPro.fulfilled, loginReducer)
      .addCase(googleLogin.fulfilled, loginReducer)
      .addCase(facebookLogin.fulfilled, loginReducer)
      .addCase(appleLogin.fulfilled, loginReducer)
      .addCase(getCurrentLocation.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getCurrentLocation.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(getCurrentLocation.fulfilled, (state, { payload }) => {
        state.location = payload.data.country;
        state.isFetching = false;
      })
      .addCase(signupUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(signupUser.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(signupUser.fulfilled, (state, { payload }) => {
        state.userData = { ...payload };
        state.account_id = payload.data.id;
        state.isFetching = false;
        state.token = payload.data.access;
        state.refreshToken = payload.data.refresh;
        state.isRegistering = true;
        state.ipCountry = payload.data.ip_country;
      })
      .addCase(signupUserPro.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(signupUserPro.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(signupUserPro.fulfilled, (state, { payload }) => {
        state.userData = { ...payload };
        state.account_id = payload.data.id;
        state.isFetching = false;
        state.token = payload.data.access;
        state.refreshToken = payload.data.refresh;
        state.isRegistering = true;
        state.userType = USER_TYPE.FACILITY_MEMBER;
      })
      .addCase(setPasswordGuestAdmin.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(setPasswordGuestAdmin.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(setPasswordGuestAdmin.fulfilled, (state, { payload }) => {
        state.userData = { ...payload };
        state.account_id = payload.data.id;
        state.isFetching = false;
        state.token = payload.data.access;
        state.refreshToken = payload.data.refresh;
        state.isRegistering = true;
      })
      .addCase(setMemberPassword.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(setMemberPassword.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(setMemberPassword.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.userData = { ...payload };
        state.account_id = payload.data.id;
        state.token = payload.data.access;
        state.refreshToken = payload.data.refresh;
        state.isRegistering = true;
      })
      .addCase(forgotPasswordUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(forgotPasswordUser.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(forgotPasswordUser.fulfilled, (state, { payload }) => {
        state.userData = { ...payload };
        state.isFetching = false;
      })
      .addCase(resetPasswordUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(resetPasswordUser.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(resetPasswordUser.fulfilled, (state, { payload }) => {
        state.userData = { ...payload };
        state.isFetching = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(logoutUser.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.userData = {};
        state.account_id = '';
        state.isFetching = false;
        state.token = '';
        state.refreshToken = '';
        state.isRegistering = false;
        // state.veraType = VERA_TYPE.UNSELECTED;
        state.userType = USER_TYPE.UNSELECTED;
        state.resident_id = '';
        state.listenerMode = false;
      })
      .addCase(notifyUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(notifyUser.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(notifyUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.userData = { ...payload };
      })
      .addCase(refreshAccessToken.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(refreshAccessToken.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.token = payload.data.access;
        // state.isRegistering = false;
      })
      .addCase(refreshAccessToken.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(verifyEmailRequiring.pending, (state) => {
        state.isFetchingVerifying = true;
      })
      .addCase(verifyEmailRequiring.fulfilled, (state) => {
        state.isFetchingVerifying = false;
      })
      .addCase(verifyEmailRequiring.rejected, (state) => {
        state.isFetchingVerifying = false;
      })
      .addCase(verifyEmail.pending, (state) => {
        state.isFetchingVerifying = true;
      })
      .addCase(verifyEmail.fulfilled, (state, { payload }) => {
        state.isFetchingVerifying = false;
        state.email_verified = payload?.data?.email_verified || false;
      })
      .addCase(verifyEmail.rejected, (state, { payload }) => {
        state.isFetchingVerifying = false;
      });
  },
});

const {
  clearUser,
  registrationFinish,
  setIsRegistering,
  setVerifyToken,
  setShowHowAreYouModal,
  setListenerMode,
  appendTokens,
  setVeraType,
  setResidentId,
} = authSlice.actions;

export {
  loginUser,
  logoutUser,
  signupUser,
  signupUserPro,
  forgotPasswordUser,
  resetPasswordUser,
  clearUser,
  refreshAccessToken,
  notifyUser,
  registrationFinish,
  setIsRegistering,
  verifyEmailRequiring,
  verifyEmail,
  setShowHowAreYouModal,
  setListenerMode,
  googleLogin,
  facebookLogin,
  appleLogin,
  appendTokens,
  setPasswordGuestAdmin,
  setVeraType,
  getCurrentLocation,
  loginUserPro,
  setResidentId,
  setMemberPassword,
};

const persistConfig = {
  key: REDUCER_KEY_AUTH,
  storage: persistStorage,
  whitelist: ['token', 'refreshToken', 'isGuest', 'isRegistering', 'listenerMode', 'veraType', 'userType', 'resident_id', 'ipCountry'],
};

const persistedReducer = persistReducer(
  persistConfig,
  authSlice.reducer,
);

export default persistedReducer;

/* eslint-disable no-param-reassign */
