import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  EMODALS, FREE_MUSIC_DELAY,
  REDUCER_KEY_ARTISTS,
  REDUCER_KEY_AUTH,
  REDUCER_KEY_PLAYER,
  REDUCER_KEY_PROFILE,
  REDUCER_KEY_TRACKS, USER_TYPE,
} from '../../constants';
import {
  clearPlayer,
  fetchLicenseToken,
  setPlay,
  setPlayingTrack,
} from '../../store/reducers/playerReducer';
import {
  getFairplayCert, getTimeDifference,
  getTimeFromSeconds,
  isIpadOS,
} from '../../helpers';
import useDrmType from '../../hooks/useDrmType';
import useOS from '../../hooks/useOS';
import useCommonState from '../../hooks/useCommonState';
import PlayerButton from './PlayerButton';
import Icon, { ICON_NAMES } from '../Icons';
import { ITrack } from '../../types';
import LikeModal from '../ModalWindow/LikeModal';
import DeleteModal from '../ModalWindow/DeleteModal';
import useModal from '../../hooks/useModal';
import StartTrialModal from '../ModalWindow/StartTrialModal';
import { updateTracksPreferences } from '../../store/reducers/tracksReducer';
import useProfileData from '../../hooks/useProfileData';
import usePro from '../../hooks/usePro';
import { REACT_APP_LICENSE_PALLYCON_URL } from '../../config';

const shaka2 = require('../../../node_modules/shaka-player2');
const shaka3 = require('../../../node_modules/shaka-player3');

interface IShakaPlayer {
  small?: boolean;
}

const MusicPlayer: React.FunctionComponent<IShakaPlayer> = ({
  small,
}) => {
  const dispatch = useAppDispatch();
  const { drmType } = useDrmType();
  const { os } = useOS();
  const { isMobile } = useCommonState();
  const { profileData } = useProfileData();
  const { isPro } = usePro();
  const {
    playlist,
    licenseToken,
    player,
    playingTrackId,
    tracksPreferences,
    artistsPreferences,
    isFetchingLicenseToken,
    userToken,
    userType,
    profilesState,
  } = useAppSelector((state) => ({
    playlist: state[REDUCER_KEY_PLAYER].playlist,
    licenseToken: state[REDUCER_KEY_PLAYER].licenseToken,
    player: state[REDUCER_KEY_PLAYER],
    playingTrackId: state[REDUCER_KEY_PLAYER].playingTrackId,
    tracksPreferences: state[REDUCER_KEY_TRACKS].tracksPreferences,
    artistsPreferences: state[REDUCER_KEY_ARTISTS].favoritesArtists,
    isFetchingLicenseToken: state[REDUCER_KEY_PLAYER].isFetchingLicenseToken,
    userToken: state[REDUCER_KEY_AUTH].token,
    userType: state[REDUCER_KEY_AUTH].userType,
    profilesState: state[REDUCER_KEY_PROFILE].profilesState,
  }));
  const shaka = useMemo(() => {
    if (os === 'Android') {
      return shaka3;
    }
    return shaka2;
  }, [os]);

  const [trackProgress, setTrackProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setMuted] = useState(false);
  const [isShuffled, setShuffled] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [isPlayingNext, setIsPlayingNext] = useState(true);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  // indexes arrangement for order
  const [trackListIndexes, setTrackListIndexes] = useState<Array<number>>([]);
  const [shakaInstance, setShakaInstance] = useState(new shaka.Player());
  const [currentModal, setCurrentModal] = useState('');
  const [isDisabledEdge, setDisabledEdge] = useState('');
  const { modalIsOpen, toggleModalOpen } = useModal();

  let timeDiff = 0;
  if (profileData && profileData.created_at) {
    timeDiff = getTimeDifference(new Date(profileData.created_at), new Date());
  }

  const audioRef = useRef(null);
  const intervalRef = useRef();
  const isReady = useRef(false);
  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : '0%';
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #7A23D3), color-stop(${currentPercentage}, ${isMobile && small ? '#B9B9B9' : '#fff'}))
  `;

  const licenseUrl = REACT_APP_LICENSE_PALLYCON_URL;

  const isLikedTrack = useMemo(
    () => !!tracksPreferences.likes?.find((track) => track.id === playingTrackId),
    [playingTrackId, tracksPreferences],
  );

  const isDislikedTrack = useMemo(
    () => !!tracksPreferences.dislikes?.find((track) => track.id === playingTrackId),
    [playingTrackId, tracksPreferences],
  );

  const getplayingArtistId = playlist?.tracks?.find(
    (track) => track.id === playingTrackId,
  )?.main_artists[0]?.id;

  const isDislikedArtist = useMemo(
    () => !!artistsPreferences.dislikes?.find((artist) => artist.id === getplayingArtistId),
    [getplayingArtistId, artistsPreferences],
  );
  const indexInTrackList = useMemo(
    () => trackListIndexes
      .findIndex((item, index) => item === currentTrackIndex),
    [trackListIndexes, currentTrackIndex],
  );

  const isProfileInUse = useMemo(
    () => profilesState
      ?.some((profileState) => profileState.id === profileData.id && profileState.listening),
    [profilesState, profileData.id],
  );

  const handleToggleLike = useCallback(() => {
    if (!isLikedTrack) {
      setCurrentModal(EMODALS.LIKE_SONG);
      toggleModalOpen();
    } else {
      dispatch(updateTracksPreferences({
        id: `${profileData?.id}`,
        action: 'reset',
        tracks: [playlist?.tracks[currentTrackIndex]?.id as string],
      }));
    }
  }, [
    toggleModalOpen,
    dispatch,
    isLikedTrack,
    profileData,
    playlist,
    currentTrackIndex,
  ]);

  const handleRemoveSong = useCallback(() => {
    setCurrentModal(EMODALS.REMOVE_SONG);
    toggleModalOpen();
  }, [toggleModalOpen]);

  const handlePay = useCallback(() => {
    setCurrentModal(EMODALS.START_TRIAL);
    toggleModalOpen();
  }, [toggleModalOpen]);

  const parsingResponse = (response: any) => {
    try {
      let responseText = shaka.util.StringUtils.fromUTF8(response.data);
      // Trim whitespace.
      responseText = responseText.trim();

      const pallyconObj = JSON.parse(responseText);
      if (pallyconObj && pallyconObj.errorCode && pallyconObj.message) {
        // this response is error, not valid widevine license

        let fullErrorMsg;
        if (parseInt(pallyconObj.errorCode, 10) === 8002) {
          // getting the error from gateway page
          const errorObj = JSON.parse(pallyconObj.message);
          if (errorObj && errorObj.ERROR && errorObj.MESSAGE) {
            fullErrorMsg = `Gateway ERROR: ${errorObj.ERROR} / Gateway MESSAGE: ${errorObj.MESSAGE}`;
          }
        } else {
          // this error is from PallyCon RI
          fullErrorMsg = `PallyCon errorCode: ${pallyconObj.errorCode} / Error message: ${pallyconObj.message}`;
        }
      }
    } catch (error) {
      // console.log('error', error);
    }
  };

  const initPlayer = () => {
    let contentUri;
    let playerConfig;

    shakaInstance.attach(audioRef.current);
    // @ts-ignore
    audioRef.current.volume = isMuted ? 0 : volume;

    if (drmType === 'FairPlay') {
      contentUri = playlist?.tracks[currentTrackIndex].hls_url;
      const fairplayCert = getFairplayCert();
      playerConfig = {
        drm: {
          servers: {
            'com.apple.fps.1_0': licenseUrl,
          },
          advanced: {
            'com.apple.fps.1_0': {
              serverCertificate: fairplayCert,
            },
          },
          initDataTransform: (initData: any) => {
            const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
            const contentId = skdUri.substring(skdUri.indexOf('skd://') + 6);
            const cert = shakaInstance.drmInfo().serverCertificate;
            return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
          },
        },
      };

      shakaInstance.getNetworkingEngine().registerRequestFilter((type: any, request: any) => {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          const originalPayload = new Uint8Array(request.body);
          const base64Payload = shaka.util.Uint8ArrayUtils.toBase64(originalPayload);
          const params = `spc=${encodeURIComponent(base64Payload)}`;

          request.uri = `${licenseUrl}?${params}`;
          request.boy = base64Payload;
          request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
          request.headers['pallycon-customdata-v2'] = licenseToken;
        }
      });

      shakaInstance.getNetworkingEngine().registerResponseFilter((type: any, response: any) => {
        // Alias some utilities provided by the library.
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          parsingResponse(response);
        }
      });
    } else {
      contentUri = playlist?.tracks[currentTrackIndex]?.dash_url;
      if (drmType === 'Widevine') {
        playerConfig = {
          drm: {
            servers: {
              'com.widevine.alpha': licenseUrl,
            },
          },
        };
      } else {
        playerConfig = {
          drm: {
            servers: {
              'com.microsoft.playready': licenseUrl,
            },
          },
        };
      }
      shakaInstance.getNetworkingEngine().registerRequestFilter((type: any, request: any) => {
        // Only add headers to license requests:
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          request.headers['pallycon-customdata-v2'] = licenseToken;
        }
      });
      shakaInstance.getNetworkingEngine().registerResponseFilter((type: any, response: any) => {
        // Alias some utilities provided by the library.
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          parsingResponse(response);
        }
      });
    }

    // Try to load a manifest.
    // This is an asynchronous process.
    shakaInstance.load(contentUri).then(() => {
      // This runs if the asynchronous load is successful.
      if (isPro || profileData?.subscribed || timeDiff < FREE_MUSIC_DELAY) {
        // @ts-ignore
        setDuration(parseInt(audioRef.current.duration, 10));
      } else {
        setDuration(30);
      }
    }).catch((error: any) => {
      // console.log('error', error);
    }); // onError is executed if the asynchronous load fails.
    shakaInstance.configure(playerConfig);
  };

  const formattedProgress = useMemo(() => {
    const str = getTimeFromSeconds(trackProgress, true);
    return str;
  }, [trackProgress]);

  const formattedDuration = useMemo(() => {
    if (duration) {
      return getTimeFromSeconds(duration, small);
    }
    return '00:00';
  }, [duration, small]);

  const shuffle = useCallback(() => {
    // change indexes arrangement for shuffling
    if (playlist && playlist.tracks.length) {
      const newArray = playlist.tracks
        .map((item, index) => index)
        .slice(currentTrackIndex + 1)
        .sort(() => Math.random() - 0.5);
      const newArrayFirst = playlist.tracks
        .slice(0, currentTrackIndex)
        .map((item, index) => index)
        .sort(() => Math.random() - 0.5);
      setTrackListIndexes([...newArrayFirst, currentTrackIndex, ...newArray]);
    }
  }, [currentTrackIndex, playlist]);

  const handleToggleShuffle = useCallback(() => {
    if (playlist && playlist.tracks.length) {
      if (!player.isPlaying) {
        isReady.current = false;
      }
      if (isShuffled) {
        setShuffled(false);
        setTrackListIndexes(playlist.tracks.map((item, index) => index));
      } else {
        setShuffled(true);
        shuffle();
      }
    }
  }, [isShuffled, shuffle, playlist, player.isPlaying]);
  const handleClickPrevious = useCallback(() => {
    setIsPlayingNext(false);
    if (playlist && playlist.tracks.length) {
      if (indexInTrackList - 1 >= 0) {
        setCurrentTrackIndex(trackListIndexes[indexInTrackList - 1]);
        const track = playlist.tracks
          .find((item, index) => index === trackListIndexes[indexInTrackList - 1]);
        dispatch(setPlayingTrack({ trackId: (track as ITrack).id }));
      } else if (repeat) {
        setCurrentTrackIndex(trackListIndexes[playlist.tracks.length - 1]);
        const track = playlist.tracks
          .find((item, index) => index === trackListIndexes[playlist.tracks.length - 1]);
        dispatch(setPlayingTrack({ trackId: (track as ITrack).id }));
      } else if (isDislikedTrack) {
        setDisabledEdge('first');
      }
    }
  }, [
    trackListIndexes,
    playlist,
    repeat,
    dispatch,
    isDislikedTrack,
    indexInTrackList,
  ]);
  const handleClickNext = useCallback(() => {
    setIsPlayingNext(true);
    if (playlist && playlist.tracks.length && trackListIndexes.length) {
      if (indexInTrackList < playlist.tracks.length - 1) {
        setCurrentTrackIndex(trackListIndexes[indexInTrackList + 1]);
        const track = playlist.tracks
          .find((item, index) => index === trackListIndexes[indexInTrackList + 1]);
        dispatch(setPlayingTrack({ trackId: (track as ITrack)?.id }));
      } else if (repeat) {
        setCurrentTrackIndex(trackListIndexes[0]);
        const track = playlist.tracks.find((item, index) => index === trackListIndexes[0]);
        dispatch(setPlayingTrack({ trackId: (track as ITrack).id }));
      } else if (isDislikedTrack) {
        setDisabledEdge('last');
      } else {
        dispatch(setPlay({ isPlaying: false }));
      }
    } else {
    // not ready
    }
    // eslint-disable-next-line
  }, [
    trackListIndexes,
    playlist,
    currentTrackIndex,
    repeat,
    dispatch,
    isDislikedTrack,
    indexInTrackList,
  ]);
  const handleTogglePlay = useCallback(() => {
    dispatch(setPlay({ isPlaying: !player.isPlaying }));
  }, [player.isPlaying, dispatch]);
  const handleToggleRepeat = useCallback(() => {
    if (!player.isPlaying) {
      isReady.current = false;
    }
    if (repeat) {
      setRepeat(false);
    } else {
      setRepeat(true);
    }
  }, [repeat, player.isPlaying]);
  const handleToggleMute = useCallback(() => {
    // @ts-ignore
    if (audioRef.current.volume > 0) {
      // @ts-ignore
      audioRef.current.volume = 0;
      setMuted(true);
    } else {
      // @ts-ignore
      audioRef.current.volume = volume;
      setMuted(false);
    }
  }, [volume]);
  const handleIncreaseVolume = useCallback(() => {
    if (volume < 1) {
      if (volume === 0) {
        setMuted(false);
      }
      const newVolume = parseFloat((volume + 0.1).toFixed(1));
      setVolume(newVolume);
    }
  }, [volume]);
  const handleDecreaseVolume = useCallback(() => {
    if (volume > 0) {
      if (volume === 0.1) {
        setMuted(true);
      }
      const newVolume = parseFloat((volume - 0.1).toFixed(1));
      setVolume(newVolume);
    }
  }, [volume]);

  const startTimer = useCallback(() => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    // @ts-ignore
    audioRef.current.addEventListener('ended', () => {
      // @ts-ignore
      if (indexInTrackList < playlist.tracks.length - 1) {
        dispatch(setPlay({ isPlaying: true }));
        handleClickNext();
      } else {
        dispatch(setPlay({ isPlaying: false }));
        // @ts-ignore
        audioRef.current.currentTime = 0;
        // @ts-ignore
        setTrackProgress(0);
      }
    }, {
      once: true,
    });

    if (!isPro && !profileData?.subscribed && timeDiff >= FREE_MUSIC_DELAY) {
      // @ts-ignore
      audioRef.current.addEventListener('progress', (event) => {
        if (event.detail?.progress >= 30) {
          dispatch(setPlay({ isPlaying: false }));
          handleClickNext();
          handlePay();
        }
      });
    }

    // @ts-ignore
    intervalRef.current = setInterval(() => {
      // @ts-ignore
      if (!audioRef.current.ended) {
        // @ts-ignore
        setTrackProgress(audioRef.current.currentTime);

        const eventProgress = new CustomEvent('progress', {
          detail: {
            // @ts-ignore
            progress: audioRef.current.currentTime,
          },
        });

        // @ts-ignore
        audioRef.current.dispatchEvent(eventProgress);
      }
      // @ts-ignore
    }, [1000]);
  }, [
    isPro,
    handleClickNext,
    indexInTrackList,
    playlist,
    dispatch,
    profileData,
    handlePay,
    timeDiff,
  ]);

  const onScrub = useCallback((value: string) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    // @ts-ignore
    audioRef.current.currentTime = parseInt(value, 10);
    // @ts-ignore
    setTrackProgress(audioRef.current.currentTime);
  }, [setTrackProgress]);

  const onScrubEnd = useCallback(() => {
    startTimer();
  }, [startTimer]);

  useEffect(() => {
    setShakaInstance(new shaka.Player());
    // eslint-disable-next-line
  }, [os]);

  useEffect(() => {
    // @ts-ignore
    audioRef.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    if (trackListIndexes.length) {
      if (player.isPlaying) {
        // @ts-ignore
        audioRef.current.play();
        startTimer();
      } else {
        // @ts-ignore
        audioRef.current.pause();
      }
    }
    // eslint-disable-next-line
  }, [player.isPlaying, trackListIndexes]);

  // Set trackIndexesList - arrangement of tracks for playing
  useEffect(() => {
    if (playlist && playlist.tracks.length) {
      if (player.isShuffled) {
        setShuffled(true);
        shuffle();
      } else {
        setTrackListIndexes(playlist.tracks.map((item, index) => index));
      }

      if (playingTrackId) {
        const trackIndex = playlist.tracks.findIndex((item) => item.id === playingTrackId);
        setCurrentTrackIndex(trackIndex);
      }
    } // eslint-disable-next-line
  }, [playlist]);

  // setting current track index
  useEffect(() => {
    if (playlist && playlist.tracks.length) {
      if (isDislikedTrack || isDislikedArtist) {
        // eslint-disable-next-line
        isPlayingNext ? handleClickNext() : handleClickPrevious();
      } else {
        const trackIndex = playlist.tracks.findIndex((item) => item.id === playingTrackId);
        if (trackIndex !== currentTrackIndex) {
          setCurrentTrackIndex(trackIndex);
        }
      }
    } // eslint-disable-next-line
  }, [playingTrackId]);

  // getting license
  useEffect(() => {
    if (playlist && playlist.tracks.length && profileData) {
      if (userType === USER_TYPE.FACILITY_MEMBER) {
        dispatch(fetchLicenseToken({
          drm_type: `${drmType}`,
          track_id: playlist?.tracks[currentTrackIndex]?.id,
          profile_id: profileData.id,
          playlist_name: playlist?.name || '',
          force: isProfileInUse,
        }));
      } else {
        dispatch(fetchLicenseToken({
          drm_type: `${drmType}`,
          track_id: playlist?.tracks[currentTrackIndex]?.id,
          profile_id: profileData.id,
          playlist_name: playlist?.name || '',
          force: isProfileInUse,
        }));
      }
      // if ('mediaSession' in window.navigator) {
      //   navigator.mediaSession.metadata = new MediaMetadata({
      //     title: playlist?.tracks[currentTrackIndex].title,
      //     artist: playlist?.tracks[currentTrackIndex].main_artists[0].name,
      //   });
      // }
    }
    // eslint-disable-next-line
  }, [playlist, currentTrackIndex, profileData]);

  useEffect(() => {
    if (drmType && licenseToken && audioRef) {
      shaka.polyfill.installAll();
      if (shaka.Player.isBrowserSupported()) {
        initPlayer();
        if (isReady.current && player.isPlaying) {
          // @ts-ignore
          audioRef.current.play();
          startTimer();
        } else {
          // Set the isReady ref as true for the next pass
          isReady.current = true;
        }
      } else {
        console.error('Browser not supported!');
      }
    }
    // eslint-disable-next-line
  }, [licenseToken]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    if (audioRef.current) {
      // @ts-ignore
      audioRef.current.pause();
      if (!isReady.current && player.isPlaying) {
        // Set the isReady ref as true for the next pass
        isReady.current = true;
      }
    } // eslint-disable-next-line
  }, [currentTrackIndex]);

  // Pause and clean up on unmount
  useEffect(() => (() => {
    if (audioRef.current) {
      // @ts-ignore
      audioRef.current.pause();
    }
    clearInterval(intervalRef.current);
    isReady.current = false;
    dispatch(clearPlayer({}));
    // eslint-disable-next-line
  }), []);

  // Next song after remove
  useEffect(() => {
    if (isDislikedTrack || isDislikedArtist) {
      if (isPlayingNext) {
        handleClickNext();
      } else {
        handleClickPrevious();
      }
    } // eslint-disable-next-line
  }, [isDislikedTrack, isDislikedArtist, trackListIndexes]);

  useEffect(() => {
    if (isDisabledEdge === 'first') {
      handleClickNext();
    } else if (isDisabledEdge === 'last') {
      handleClickPrevious();
    }
    setDisabledEdge('');
    // eslint-disable-next-line
  }, [isDisabledEdge]);

  useEffect(() => {
    if (!licenseToken && playlist && currentTrackIndex && profileData) {
      if (userType === USER_TYPE.FACILITY_MEMBER) {
        dispatch(fetchLicenseToken({
          drm_type: `${drmType}`,
          track_id: playlist?.tracks[currentTrackIndex]?.id,
          profile_id: profileData.id,
          playlist_name: playlist?.name || '',
          force: isProfileInUse,
        }));
      } else {
        dispatch(fetchLicenseToken({
          drm_type: `${drmType}`,
          track_id: playlist?.tracks[currentTrackIndex]?.id,
          profile_id: profileData.id,
          playlist_name: playlist?.name || '',
          force: isProfileInUse,
        }));
      }
    } // eslint-disable-next-line
  }, [userToken]);

  const getControls = () => (
    <div className="music-player__controls">
      <PlayerButton
        icon={ICON_NAMES.SHUFFLE}
        className="music-player__button--shuffle"
        label="Shuffle"
        onClick={handleToggleShuffle}
        active={isShuffled}
      />
      <PlayerButton
        icon={ICON_NAMES.PREVIOUS}
        className="music-player__button--previous"
        label="Previous"
        onClick={handleClickPrevious}
        disabled={isFetchingLicenseToken}
      />
      <PlayerButton
        icon={ICON_NAMES.PLAY}
        className="music-player__button--play"
        label="Play"
        onClick={handleTogglePlay}
        active={player.isPlaying}
      />
      <PlayerButton
        icon={isMobile && small ? ICON_NAMES.NEXT_ROUNDED : ICON_NAMES.NEXT}
        className="music-player__button--next"
        label="Next"
        onClick={handleClickNext}
        disabled={isFetchingLicenseToken}
      />
      <PlayerButton
        icon={ICON_NAMES.REPEAT}
        className="music-player__button--repeat"
        label="Repeat"
        onClick={handleToggleRepeat}
        active={repeat}
      />
    </div>
  );

  return (
    <div
      className={classNames('music-player', {
        'music-player--small': small,
      })}
    >
      {/* eslint-disable-next-line */}
      <audio
        ref={audioRef}
        controls
        playsInline
        className="music-player__node"
        autoPlay={player.isPlaying}
      >
        video
      </audio>
      <div className="music-player__track-container">
        <div className="music-player__track-cover">
          {
            playlist?.tracks[currentTrackIndex]
            && (
              playlist?.tracks[currentTrackIndex].image
                ? (
                  <img
                    src={playlist?.tracks[currentTrackIndex].image}
                    alt={playlist?.tracks[currentTrackIndex].title}
                    title={playlist?.tracks[currentTrackIndex].title}
                  />
                )
                : (<span className="music-player__track-cover-text">{playlist?.tracks[currentTrackIndex].title.charAt(0)}</span>)
            )
          }
        </div>
        <div className="music-player__track-details">
          <div className="music-player__track-header">
            <span
              className={classNames('music-type-label music-player__track-label', {
                'music-type-label--relax': (`${playlist?.name}`).toLowerCase() === 'relax',
                'music-type-label--reminisce': (`${playlist?.name}`).toLowerCase() === 'reminisce' || (`${playlist?.name}`).toLowerCase() === 'memory',
                'music-type-label--energise': (`${playlist?.name}`).toLowerCase() === 'energise',
              })}
            >
              {playlist?.name}
            </span>
            {
              !small
              && (
                <div className="music-player__track-header-buttons">
                  <button
                    type="button"
                    className="music-player__action-button music-player__like-button"
                    onClick={handleToggleLike}
                  >
                    <Icon
                      name={ICON_NAMES.LIKE}
                      active={isLikedTrack}
                    />
                    {isLikedTrack ? 'Liked' : 'Like'}
                  </button>
                  {
                    !isLikedTrack
                    && (
                      <button
                        type="button"
                        className="music-player__action-button music-player__trash-button"
                        onClick={handleRemoveSong}
                        disabled={isLikedTrack}
                      >
                        <Icon name={ICON_NAMES.TRASH} className="icon--trash" />
                        Remove
                      </button>
                    )
                  }
                </div>
              )
            }
          </div>
          <div className="music-player__track-title">
            {playlist?.tracks[currentTrackIndex]?.title}
          </div>
          <div className="music-player__track-author">
            by {playlist?.tracks[currentTrackIndex]?.main_artists[0]?.name}
          </div>
          {
            !small
            && (
              <div className="music-player__track-data">
                <span className="music-player__track-year">{playlist?.tracks[currentTrackIndex]?.release_year}</span>
                <span className="music-player__track-data__separator" />
                <span className="music-player__track-duration">{formattedDuration}</span>
              </div>
            )
          }
        </div>
      </div>
      <div className="music-player__middle">
        {
          small
          && getControls()
        }
        <div className="music-player__progress-container">
          {
            small
            && (
              <div className="music-player__time music-player__time--progress">{formattedProgress}</div>
            )
          }
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration || `${duration}`}
            className="music-player__progress"
            onChange={(e) => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
            style={{ background: trackStyling }}
            disabled={!isPro && !profileData?.subscribed && timeDiff >= FREE_MUSIC_DELAY}
          />
          {
            small
            && (
              <div className="music-player__time music-player__time--duration">{formattedDuration}</div>
            )
          }
        </div>
      </div>
      <div className="music-player__controls-container">
        {
          !small
          && (
            <div className="music-player__duration">
              <span className="music-player__duration-title music-player__label">Duration</span>
              <span className="music-player__duration-number music-player__label">{formattedDuration}</span>
            </div>
          )
        }
        {
          !small
          && getControls()
        }
        <div className="music-player__volume-controls">
          <PlayerButton
            label="Volume"
            containerClassName="music-player__button-container--volume"
          >
            <div className="music-player__volume">
              <button
                className="music-player__button music-player__button--volume music-player__button--decrease"
                type="button"
                onClick={handleDecreaseVolume}
              >
                <Icon
                  name={ICON_NAMES.DECREASE}
                />
              </button>
              <Icon
                name={ICON_NAMES.VOLUME}
              />
              <button
                className="music-player__button music-player__button--volume music-player__button--increase"
                type="button"
                onClick={handleIncreaseVolume}
              >
                <Icon
                  name={ICON_NAMES.INCREASE}
                />
              </button>
            </div>
          </PlayerButton>
          {
            !isIpadOS()
            && (
              <PlayerButton
                icon={ICON_NAMES.MUTE}
                containerClassName="music-player__button-container--mute"
                className="music-player__button--volume music-player__button--mute"
                label="Mute"
                onClick={handleToggleMute}
                active={isMuted}
              />
            )
          }
        </div>
      </div>

      {
        playlist
        && currentTrackIndex !== undefined
        && (
          <>
            <LikeModal
              modalIsOpen={currentModal === EMODALS.LIKE_SONG && modalIsOpen}
              toggleModalOpen={toggleModalOpen}
              track={{
                id: playlist?.tracks[currentTrackIndex]?.id,
                title: playlist?.tracks[currentTrackIndex]?.title,
                mainArtist: playlist?.tracks[currentTrackIndex]?.main_artists[0],
              }}
            />
            <DeleteModal
              modalIsOpen={currentModal === EMODALS.REMOVE_SONG && modalIsOpen}
              toggleModalOpen={toggleModalOpen}
              track={{
                id: playlist?.tracks[currentTrackIndex]?.id,
                title: playlist?.tracks[currentTrackIndex]?.title,
                mainArtist: playlist?.tracks[currentTrackIndex]?.main_artists[0],
              }}
            />
          </>
        )
      }
      <StartTrialModal
        modalIsOpen={currentModal === EMODALS.START_TRIAL && modalIsOpen}
        toggleModalOpen={toggleModalOpen}
      />
    </div>
  );
};

export default MusicPlayer;
