import { useCallback, useEffect } from 'react';
import { REACT_APP_PROFILES_CHECK } from '../config';
import { REDUCER_KEY_AUTH, VERA_TYPE } from '../constants';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchProfilesState } from '../store/reducers/profileReducer';

export default function useIntervalFetchProfilesState() {
  const dispatch = useAppDispatch();
  const { veraType, isTokenExist } = useAppSelector((state) => ({
    veraType: state[REDUCER_KEY_AUTH].veraType,
    isTokenExist: !!state[REDUCER_KEY_AUTH].token,
  }));
  const interval = Number(REACT_APP_PROFILES_CHECK);

  const intervaledAction = useCallback(() => {
    dispatch(fetchProfilesState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => console.log(
      `Requests Profiles each ${interval / 1e3}s (${Math.round(
        interval / 6e4,
      )} minute(s))`,
    ),
    [interval],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (veraType === VERA_TYPE.PRO && isTokenExist) {
      intervaledAction();
      const ping = setInterval(intervaledAction, interval);
      return () => clearInterval(ping);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veraType, isTokenExist]);

  return null;
}
