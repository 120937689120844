import React from 'react';
import classNames from 'classnames';

interface ITextarea {
  placeholder: string;
  value: string | undefined;
  name: string;

  onChange(event: React.ChangeEvent<any>): void;

  error?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
}

const Textarea: React.FunctionComponent<ITextarea> = ({
  disabled,
  placeholder,
  label,
  value,
  onChange,
  error,
  name,
  className,
}) => (
  <label
    className={classNames('field field--textarea', {
      [`${className}`]: className,
      'field--error': error,
      'field--disabled': disabled,
    })}
    htmlFor={name}
  >
    {
      label
      && (
        <span
          className="field__label"
        >
          {label}
        </span>
      )
    }
    <div className="field__container">
      <textarea
        name={name}
        placeholder={placeholder}
        className="field__textarea"
        onChange={onChange}
      >
        {value}
      </textarea>
    </div>
    {
      error
      && (
        <span className="field__error">{error}</span>
      )
    }
  </label>
);

export default Textarea;
