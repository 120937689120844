import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  EMODALS,
  CARER_MODE_MENU_LINKS,
  CARER_MODE_MOBILE_MENU_LINKS,
  LISTENER_MODE_MENU_LINKS,
  LISTENER_MODE_MOBILE_MENU_LINKS,
  MENU_LINKS,
  MOBILE_MENU_LINKS,
  REDUCER_KEY_AUTH, REDUCER_KEY_PROFILE,
} from '../../constants';
import useCommonState from '../../hooks/useCommonState';
import usePro from '../../hooks/usePro';
import useModal from '../../hooks/useModal';
import LogoutModal from './LogoutModal';
import MenuItem from './MenuItem';
import {
  clearUser,
  logoutUser,
  setResidentId,
} from '../../store/reducers/authReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Icons, { ICON_NAMES } from '../Icons';
import ROUTES from '../../routes/constants';
import StartTrialModal from '../ModalWindow/StartTrialModal';
import ResidentsModal from '../ModalWindow/ResidentsModal';
import ResidentMenuCard from './ResidentMenuCard';
import SwitchMenuButton from './SwitchMenuButton';
import { clearPlayer } from '../../store/reducers/playerReducer';

interface IMenu {
  toggleChat?(): void,
  isChatOpen?: boolean,
}

const Menu: React.FunctionComponent<IMenu> = ({ toggleChat, isChatOpen }) => {
  const [currentModal, setCurrentModal] = useState('');
  const { isMobile, isTablet } = useCommonState();
  const { modalIsOpen, toggleModalOpen } = useModal();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { isPro } = usePro();
  const {
    refreshToken,
    listenerMode,
    profiles,
    activeResident,
    resident_id,
  } = useAppSelector((state) => ({
    refreshToken: state[REDUCER_KEY_AUTH].refreshToken,
    listenerMode: state[REDUCER_KEY_AUTH].listenerMode,
    profiles: state[REDUCER_KEY_PROFILE].profiles,
    activeResident: state[REDUCER_KEY_PROFILE].activeResident,
    resident_id: state[REDUCER_KEY_AUTH].resident_id,
  }));

  const handleModalOpen = () => {
    setCurrentModal(EMODALS.LOGOUT);
    toggleModalOpen();
  };

  const onLogout = () => {
    dispatch(logoutUser({ refreshToken }));
    dispatch(clearUser({}));
    history.push(ROUTES.LOGOUT);
    toggleModalOpen();
    setCurrentModal('');
  };

  const handlePay = useCallback(() => {
    setCurrentModal(EMODALS.START_TRIAL);
    toggleModalOpen();
  }, [toggleModalOpen]);

  const handleOpenResidentsModal = useCallback(() => {
    setCurrentModal(EMODALS.SELECT_RESIDENT);
    toggleModalOpen();
  }, [toggleModalOpen]);

  const handleSwitchMode = useCallback(() => {
    if (listenerMode) {
      dispatch(clearPlayer({}));
      dispatch(setResidentId({ resident_id: undefined }));
      history.push(ROUTES.HOME);
    } else {
      handleOpenResidentsModal();
    }
  }, [listenerMode, handleOpenResidentsModal, dispatch, history]);

  const handleSelectResident = useCallback(async (id: string) => {
    await dispatch(clearPlayer({}));
    dispatch(setResidentId({ resident_id: id }));
    toggleModalOpen();
    history.push(ROUTES.HOME);
  }, [toggleModalOpen, dispatch, history]);

  const links = useMemo(() => {
    if (isPro) {
      if (isMobile) {
        return listenerMode ? LISTENER_MODE_MOBILE_MENU_LINKS : CARER_MODE_MOBILE_MENU_LINKS;
      }
      return listenerMode ? LISTENER_MODE_MENU_LINKS : CARER_MODE_MENU_LINKS;
    }
    return isMobile ? MOBILE_MENU_LINKS : MENU_LINKS;
  }, [listenerMode, isPro, isMobile]);

  const buttonText = useMemo(() => {
    if (isTablet) {
      return listenerMode ? 'LISTENER' : 'CAREGIVER';
    }
    return listenerMode ? 'LISTENER' : 'CAREGIVER';
  }, [isTablet, listenerMode]);

  return (
    <>
      <nav className="main-menu">
        <ul className="main-menu__list">
          {
            links?.map((item: any) => {
              if (item?.type === 'button' && isPro) {
                return (
                  <React.Fragment key={item.name}>
                    <li className="main-menu__list-item">
                      <SwitchMenuButton
                        text={buttonText}
                        onSwitchMode={handleSwitchMode}
                      />
                    </li>
                    {
                      listenerMode
                      && (
                        <li className="main-menu__list-item main-menu__list-item--resident">
                          <ResidentMenuCard
                            onChangeResident={handleOpenResidentsModal}
                            account_name={`${activeResident?.account_name}`}
                            photo={activeResident?.photo}
                          />
                        </li>
                      )
                    }
                  </React.Fragment>
                );
              }
              return (
                <MenuItem
                  key={item.name}
                  name={item.name}
                  path={item.path}
                  iconName={item.iconName}
                  onClickLogout={handleModalOpen}
                  onClickPay={handlePay}
                >
                  {item.children}
                </MenuItem>
              );
            })
          }
          {
            isMobile
            && toggleChat
            && !listenerMode
            && (
              <li className="main-menu__list-item">
                <button
                  className="main-menu__link"
                  onClick={toggleChat}
                >
                  <span className="main-menu__link-container">
                    <Icons name={ICON_NAMES.CHAT} className="main-menu__icon" />
                    <span className="main-menu__link-text">Help</span>
                  </span>
                </button>
              </li>
            )
          }
        </ul>
        {
          isMobile
          && isChatOpen
          && (
            <button
              className="main-menu__close-chat"
              onClick={toggleChat}
            >
              <Icons name={ICON_NAMES.CLOSE} className="main-menu__close-chat-icon close-chat__icon" />
              <span className="main-menu__close-chat-text">Close chat</span>
            </button>
          )
        }
      </nav>
      <LogoutModal
        modalIsOpen={currentModal === EMODALS.LOGOUT && modalIsOpen}
        toggleModalOpen={toggleModalOpen}
        onConfirm={onLogout}
      />
      <StartTrialModal
        modalIsOpen={currentModal === EMODALS.START_TRIAL && modalIsOpen}
        toggleModalOpen={toggleModalOpen}
      />
      <ResidentsModal
        modalIsOpen={currentModal === EMODALS.SELECT_RESIDENT && modalIsOpen}
        toggleModalOpen={toggleModalOpen}
        onSelectResident={handleSelectResident}
      />
    </>
  );
};

export default Menu;
