import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { REACT_APP_API_URL } from '../config';
import { refreshAccessToken, logoutUser, clearUser } from '../store/reducers/authReducer';
import config, { handleRequest, handleResponse, handleResponseError } from './initialConfig';

const AxiosClient = axios.create({
  ...config,
  baseURL: REACT_APP_API_URL,
});

const AxiosClientWithoutTimeout = axios.create({
  ...config,
  timeout: 0,
  baseURL: REACT_APP_API_URL,
});

export const setupResponseInterceptor = (store: any) => {
  // Function that will be called to refresh token
  const refreshAuthLogic = (failedRequest: any): Promise<any> => store
    .dispatch(refreshAccessToken({ refreshToken: store.getState().auth.refreshToken }))
    .then((action: any) => {
      if (action.type === refreshAccessToken.rejected.type) {
        store.dispatch(logoutUser({ refreshToken: store.getState().auth.refreshToken }));
        store.dispatch(clearUser({}));
        return Promise.reject();
      }
      failedRequest.response.config.headers.Authorization = `Bearer ${store.getState().auth.token}`;
      return Promise.resolve();
    });

  // Automatically refresh token
  createAuthRefreshInterceptor(AxiosClient, refreshAuthLogic);
  createAuthRefreshInterceptor(AxiosClientWithoutTimeout, refreshAuthLogic);

  AxiosClient.interceptors.request.use(handleRequest);
  AxiosClient.interceptors.response.use(
    (response) => handleResponse(response, store),
    (error) => handleResponseError(error, store),
  );
  AxiosClientWithoutTimeout.interceptors.request.use(handleRequest);
  AxiosClientWithoutTimeout.interceptors.response.use(
    (response) => handleResponse(response, store),
    (error) => handleResponseError(error, store),
  );
};

export default AxiosClient;
export { AxiosClientWithoutTimeout };
