import { useCallback, useEffect, useState } from 'react';

const useDrmType = () => {
  const [browser, setBrowser] = useState('');
  const [drmType, setDrmType] = useState('');

  const browserCheck = useCallback(() => {
    const agent = navigator.userAgent.toLowerCase();
    const name = navigator.appName;
    if (name === 'Microsoft Internet Explorer' || agent.indexOf('trident') > -1 || agent.indexOf('edge/') > -1) {
      setBrowser('Ie');
      if (agent.indexOf('edge/') > -1) { // Edge
        setBrowser('Edge');
      }
      // we dont support PlayReady
      setDrmType('Widevine');
    } else if (agent.indexOf('safari') > -1) { // Chrome or Safari
      setDrmType('Widevine');
      if (agent.indexOf('opr') > -1) { // Opera
        setBrowser('Opera');
      } else if (agent.indexOf('whale') > -1) { // Chrome
        setBrowser('Whale');
      } else if (agent.indexOf('edg/') > -1) { // Chrome
        // we dont support PlayReady
        setBrowser('Edge');
      } else if (agent.indexOf('chrome') > -1) { // Chrome
        setBrowser('Chrome');
      } else { // Safari
        setBrowser('Safari');
        setDrmType('FairPlay');
      }
    } else if (agent.indexOf('firefox') > -1) { // Firefox
      setBrowser('firefox');
      setDrmType('Widevine');
    }
  }, []);

  useEffect(() => {
    browserCheck();
    // eslint-disable-next-line
  }, []);

  return {
    browser,
    drmType,
  };
};

export default useDrmType;
