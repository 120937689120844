import { RootState } from '..';
import { REDUCER_KEY_PROFILE } from '../../constants';
import { isSameDayWithCurrentDate, removeDays } from '../../helpers/date';

export default function isTrialsEndInSevenDaysSelector(state: RootState) {
  const trialDateEnd = state[REDUCER_KEY_PROFILE].profiles[0]?.trial_end;
  return (
    trialDateEnd && !!isSameDayWithCurrentDate(removeDays(trialDateEnd, 7))
  );
}
