import { useState, useEffect, useCallback } from 'react';

const useOS = () => {
  const [os, setOs] = useState('');

  const osCheck = useCallback(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const platform = navigator.platform.toLowerCase();

    const macosPlatforms = ['macintosh', 'macintel', 'macppc', 'mac68k'];
    const windowsPlatforms = ['win32', 'win64', 'windows', 'wince'];
    const iosPlatforms = ['iphone', 'ipad', 'ipod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
      setOs('Mac OS');
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      setOs('iOS');
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      setOs('Windows');
    } else if (/android/.test(userAgent)) {
      setOs('Android');
    } else if (!os && /linux/.test(platform)) {
      setOs('Linux');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    osCheck();
    // eslint-disable-next-line
  }, []);

  return {
    os,
  };
};

export default useOS;
