import { useEffect, useState } from 'react';
import { useAppSelector } from '../store/hooks';
import { REDUCER_KEY_AUTH, USER_TYPE, VERA_TYPE } from '../constants';

const usePro = () => {
  const { veraType } = useAppSelector((state) => ({
    veraType: state[REDUCER_KEY_AUTH].veraType,
  }));
  const [isPro, setIsPro] = useState(veraType === VERA_TYPE.PRO);

  useEffect(() => {
    if (veraType === VERA_TYPE.PRO) {
      setIsPro(true);
      return;
    }
    setIsPro(false);
  }, [veraType]);

  return {
    isPro,
  };
};

export default usePro;
