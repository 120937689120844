import React from 'react';
import Button from '../Button';
import EntityCover from '../EntityCover';

interface IResidentMenuCard {
  onChangeResident(): void;
  account_name: string;
  photo: string | undefined;
}

const ResidentMenuCard: React.FunctionComponent<IResidentMenuCard> = ({
  onChangeResident,
  account_name,
  photo,
}) => (
  <div className="resident-menu-card">
    <div className="resident-menu-card__photo-container">
      <EntityCover
        title={account_name}
        src={photo}
        rounded
        className="resident-menu-card__photo-image"
      />
    </div>
    <div className="resident-menu-card__data">
      <div className="resident-menu-card__name">{account_name}</div>
      <Button size="minimal" onClick={onChangeResident}>Change Resident</Button>
    </div>
  </div>
);

export default ResidentMenuCard;
