import React from 'react';
import ModalWindow, { ModalTitle } from '../ModalWindow';
import Button from '../Button';

interface ILogoutModal {
  modalIsOpen: boolean;
  toggleModalOpen(): void;
  onConfirm(): void;
}

const LogoutModal: React.FunctionComponent<ILogoutModal> = ({
  modalIsOpen,
  toggleModalOpen,
  onConfirm,
}) => {
  const handleConfirm = () => {
    window.Intercom('hide');
    window.Intercom('shutdown');
    onConfirm();
  };

  return (
    <ModalWindow
      modalIsOpen={modalIsOpen}
      onCloseModal={toggleModalOpen}
    >
      <ModalTitle
        title="Logout"
      />
      <p className="modal__text">
        Are you sure you want to logout now?
      </p>
      <div className="row">
        <div className="col-auto">
          <Button
            type="button"
            color="primary"
            size="middle"
            onClick={handleConfirm}
          >
            Logout
          </Button>
        </div>
        <div className="col-auto">
          <Button
            type="button"
            color="secondary"
            size="middle"
            onClick={toggleModalOpen}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
};

export default LogoutModal;
