import React from 'react';
import { LoadingComponentProps } from 'react-loadable';

const Loader: React.FunctionComponent<LoadingComponentProps> = ({
  error,
  retry,
}) => {
  if (error) {
    return (
      <div>
        Error
      </div>
    );
  }

  return (
    <span className="preloader-overlay">
      <span className="preloader-spinner">
        <span className="preloader-spinner-dot preloader-spinner-dot--dot-1" />
        <span className="preloader-spinner-dot preloader-spinner-dot--dot-2" />
        <span className="preloader-spinner-dot preloader-spinner-dot--dot-3" />
        <span className="preloader-spinner-dot preloader-spinner-dot--dot-4" />
        <span className="preloader-spinner-dot preloader-spinner-dot--dot-5" />
      </span>
    </span>
  );
};

export default Loader;
