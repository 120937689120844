import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_PROFILE,
  ACTION_NAME_FETCH_PROFILES,
  ACTION_NAME_FETCH_PLAYLISTS,
  ACTION_NAME_FETCH_PROFILE_DATA,
  ACTION_NAME_REFRESH_PLAYLISTS,
  ACTION_NAME_CREATE_PROFILE,
  ACTION_NAME_UPDATE_PROFILE,
  ACTION_NAME_PATCH_PROFILE_DATA,
  ACTION_NAME_UPDATE_PASSWORD,
  ACTION_NAME_GENERATE_PLAYLISTS,
  ACTION_NAME_FETCH_PROFILE_PHOTO,
  ACTION_NAME_UPDATE_PROFILE_PHOTO,
  ACTION_NAME_FETCH_ACCOUNT_ADMIN_INFO,
  ACTION_NAME_PUT_ACCOUNT_ADMIN_LOCATION,
  ACTION_NAME_CREATE_PROFILE_PRO,
  ACTION_NAME_FETCH_PRO_USER_PROFILE_DATA,
  ACTION_NAME_ADD_EXISTING_RESIDENT,
  ACTION_NAME_PATCH_PRO_USER_PROFILE_DATA,
  ACTION_NAME_REMIND_INVITED_RESIDENT,
  ACTION_NAME_REMOVE_INVITED_RESIDENT,
  ACTION_NAME_ADD_NEW_RESIDENT_PROFILE,
  VERA_TYPE,
  ACTION_NAME_FETCH_INVITED_RESIDENTS,
  ACTION_NAME_PATCH_INVITED_RESIDENTS,
  ACTION_NAME_ARCHIVE_LISTENER_PROFILE,
  ACTION_NAME_UNARCHIVE_LISTENER_PROFILE,
  ACTION_NAME_FETCH_PROFILES_STATE,
} from '../../constants';
import ApiClient from '../../api/ApiClient';
import {
  IProfile,
  IPlaylist,
  IAccountAdminInfo,
  IProfilePro,
  IExistingResident,
  IInvitesRequested,
} from '../../types';
import { setIsRegistering } from './authReducer';
import { RootState } from '..';

interface IProfileState {
  isFetching: boolean;
  data: Record<string, unknown> | IProfilePro | any;
  profiles: Array<IProfile>;
  playlist: IPlaylist | undefined;
  isFetchingPlaylist: boolean;
  licenseToken: string;
  accountAdminInfo: IAccountAdminInfo;
  activeResident: IProfile | undefined;
  invites: IInvitesRequested[];
  partnerId: string | undefined;
  profilesState: Array<{ id: string, listening: boolean }>;
}

interface IProfileParams {
  id: any;
}

interface AddArtists extends IProfileParams {
  artists: { artists: string[] };
}

interface UpdateProfile extends IProfileParams {
  profileData: IProfile;
}

interface UpdatePasswordParams {
  current_password: string,
  new_password: string,
  confirm_password: string,
}

const initialState: IProfileState = {
  isFetching: false,
  data: {},
  profiles: [],
  playlist: undefined,
  isFetchingPlaylist: false,
  licenseToken: '',
  accountAdminInfo: {},
  activeResident: undefined,
  invites: [],
  partnerId: undefined,
  profilesState: [],
};

const fetchProfiles = createAsyncThunk<
  AxiosResponse<Array<IProfile>>,
  undefined,
  { state: RootState }
>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PROFILES}`,
  async (arg, {
    dispatch,
    getState,
  }) => {
    const response = await ApiClient.fetchProfiles();
    const { auth } = getState();
    if (
      auth.veraType === VERA_TYPE.LISTENER
      && (!response.data.length || !response.data[0].is_complete)
    ) {
      dispatch(setIsRegistering({ isRegistering: true }));
    }
    return response;
  },
);

const fetchProfilesState = createAsyncThunk<
  AxiosResponse<Array<{id: string, listening: boolean}>>,
  undefined,
  { state: RootState }
>(`${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PROFILES_STATE}`, async () => {
  const response = await ApiClient.fetchProfilesState();
  return response;
});

const fetchAccountAdminInfo = createAsyncThunk<AxiosResponse<IAccountAdminInfo>, { token: string }>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_ACCOUNT_ADMIN_INFO}`,
  async ({
    token,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.getAccountAdminInfo(token);
    return response;
  },
);

const fetchProfileData = createAsyncThunk<AxiosResponse<any>, IProfileParams>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PROFILE_DATA}`,
  async ({
    id,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.fetchProfileData(id);
    return response;
  },
);

const fetchProUserProfileData = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PRO_USER_PROFILE_DATA}`,
  async () => {
    const response = await ApiClient.fetchProUserData();
    return response;
  },
);

const patchProUserProfileData = createAsyncThunk<AxiosResponse<any>, {
  name: string,
  email: string,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_PATCH_PRO_USER_PROFILE_DATA}`,
  async ({ name, email }) => {
    const response = await ApiClient.patchProUserData(name, email);
    return response;
  },
);

const patchProfileData = createAsyncThunk<AxiosResponse<any>, IProfile>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_PATCH_PROFILE_DATA}`,
  async ({
    id,
    current_location,
    self_created,
    preferred_name,
    date_of_birth,
    location_of_birth,
    mother_tongue,
    location_of_formative_years,
    gender,
    email,
    is_complete,
    cognitive_decline_level,
    partner,
  }) => {
    const response = await ApiClient.patchProfileData(`${id}`, {
      current_location,
      self_created,
      preferred_name,
      date_of_birth,
      location_of_birth,
      mother_tongue,
      location_of_formative_years,
      gender,
      email,
      is_complete,
      cognitive_decline_level,
      partner,
    });
    return response;
  },
);

const putAccountAdminLocation = createAsyncThunk<AxiosResponse<any>, { location: string }>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_PUT_ACCOUNT_ADMIN_LOCATION}`,
  async ({
    location,
  }) => {
    const response = await ApiClient.putAccountAdminLocation({ location: location });
    return response;
  },
);

interface IPlaylistParams {
  profileId: string;
  playlistId: string;
}

const generatePlaylists = createAsyncThunk<AxiosResponse<any>, { id: any }>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_GENERATE_PLAYLISTS}`,
  async ({
    id,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.generatePlaylists(id);
    return response;
  },
);

const fetchPlaylists = createAsyncThunk<AxiosResponse<any>, IPlaylistParams>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PLAYLISTS}`,
  async ({
    profileId,
    playlistId,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.fetchPlaylistDetails(profileId, playlistId);
    return response;
  },
);

const refreshPlaylists = createAsyncThunk<AxiosResponse<any>, IPlaylistParams>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_REFRESH_PLAYLISTS}`,
  async ({
    profileId,
    playlistId,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.refreshPlaylistDetails(profileId, playlistId);
    return { ...response, profileId, playlistId };
  },
);

const createProfile = createAsyncThunk<Promise<AxiosResponse<any>>, IProfile>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_CREATE_PROFILE}`,
  async ({
    current_location,
    self_created,
    preferred_name,
    gender,
    date_of_birth,
    location_of_birth,
    mother_tongue,
    location_of_formative_years,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.createProfile({
      current_location,
      self_created,
      preferred_name,
      gender,
      date_of_birth,
      location_of_birth,
      mother_tongue,
      location_of_formative_years,
      // eslint-disable-next-line arrow-body-style
    });
    return response.data;
  },
);

const updateProfile = createAsyncThunk<Promise<AxiosResponse<any>>, UpdateProfile>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_UPDATE_PROFILE}`,
  async ({
    id,
    profileData,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.updateProfileData(
      id,
      profileData,
      // eslint-disable-next-line arrow-body-style
    );
    return response.data;
  },
);

const updatePassword = createAsyncThunk<Promise<AxiosResponse<any>>, UpdatePasswordParams>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_UPDATE_PASSWORD}`,
  async ({
    current_password,
    new_password,
    confirm_password,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.updatePassword({
      current_password,
      new_password,
      confirm_password,
    });
    return response.data;
  },
);

const fetchProfilePhoto = createAsyncThunk<AxiosResponse<any>, { id: string }>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_PROFILE_PHOTO}`,
  async ({ id }) => {
    const response = await ApiClient.fetchProfilePhoto(id);
    return response;
  },
);

const updateProfilePhoto = createAsyncThunk<AxiosResponse<any>, { id: string, photo: string}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_UPDATE_PROFILE_PHOTO}`,
  async ({ id, photo }) => {
    const photoData = new FormData();
    photoData.append('photo', photo);
    const response = await ApiClient.updateProfilePhoto(id, photoData);
    return { ...response, profileId: id };
  },
);

const addNewResidentProfile = createAsyncThunk<AxiosResponse<any>, {
  profile: IProfile,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_ADD_NEW_RESIDENT_PROFILE}`,
  async ({ profile }) => {
    const response = await ApiClient.addNewResidentProfile(profile);
    return response;
  },
);

const createProfilePro = createAsyncThunk<Promise<AxiosResponse<any>>, IProfilePro>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_CREATE_PROFILE_PRO}`,
  async ({
    location,
    // eslint-disable-next-line arrow-body-style
  }) => {
    const response = await ApiClient.createProfilePro({
      location,
      // eslint-disable-next-line arrow-body-style
    });
    return response.data;
  },
);

const addExistingResident = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  resident: IExistingResident,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_ADD_EXISTING_RESIDENT}`,
  async ({ facility_id, resident }) => {
    const response = await ApiClient.addExistingResident(facility_id, resident);
    return response;
  },
);

const remindInvitedResident = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  invite_id: string,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_REMIND_INVITED_RESIDENT}`,
  async ({ facility_id, invite_id }) => {
    const response = await ApiClient.remindInvitedResident(facility_id, invite_id);
    return response;
  },
);

const archiveListenerProfile = createAsyncThunk<AxiosResponse<any>, {
  profile_id: string,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_ARCHIVE_LISTENER_PROFILE}`,
  async ({ profile_id }) => {
    const response = await ApiClient.archiveListenerProfile(profile_id);
    return response;
  },
);

const unarchiveListenerProfile = createAsyncThunk<AxiosResponse<any>, {
  profile_id: string,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_UNARCHIVE_LISTENER_PROFILE}`,
  async ({ profile_id }) => {
    const response = await ApiClient.unarchiveListenerProfile(profile_id);
    return response;
  },
);

const removeInvitedResident = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  invite_id: string,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_REMOVE_INVITED_RESIDENT}`,
  async ({ facility_id, invite_id }) => {
    const response = await ApiClient.deleteInvitedResident(facility_id, invite_id);
    return { ...response, invite_id };
  },
);

const fetchInvitedResidents = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_FETCH_INVITED_RESIDENTS}`,
  async ({ facility_id }) => {
    const response = await ApiClient.fetchInvitedResidents(facility_id);
    return response;
  },
);

const patchInvitedResidents = createAsyncThunk<AxiosResponse<any>, {
  facility_id: string,
  id: string,
  status: number,
}>(
  `${REDUCER_KEY_PROFILE}/${ACTION_NAME_PATCH_INVITED_RESIDENTS}`,
  async ({ facility_id, id, status }) => {
    const response = await ApiClient.patchInvitedResidents(facility_id, id, status);
    return response;
  },
);

const profileSlice = createSlice<IProfileState, SliceCaseReducers<IProfileState>,
  typeof REDUCER_KEY_PROFILE>({
    name: REDUCER_KEY_PROFILE,
    initialState,
    reducers: {
      clearProfile: (state, action: PayloadAction<Record<string, unknown>>) => {
        state.profiles = [];
      },
      setActiveResident: (state, action: PayloadAction<{ profile: IProfile }>) => {
        state.activeResident = action.payload.profile;
      },
      setParnterId: (state, action: PayloadAction<{ partnerId: string }>) => {
        state.partnerId = action.payload.partnerId;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchProfileData.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchProfileData.fulfilled, (state, { payload }) => {
          state.data = payload.data;
          state.isFetching = false;
        })
        .addCase(fetchProUserProfileData.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchProUserProfileData.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchProUserProfileData.fulfilled, (state, { payload }) => {
          state.data = payload?.data || {};
          state.isFetching = false;
        })
        .addCase(patchProUserProfileData.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(patchProUserProfileData.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(patchProUserProfileData.fulfilled, (state, { payload }) => {
          state.data = payload.data;
          state.isFetching = false;
        })
        .addCase(fetchAccountAdminInfo.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchAccountAdminInfo.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchAccountAdminInfo.fulfilled, (state, { payload }) => {
          state.accountAdminInfo = payload.data;
          state.isFetching = false;
        })
        .addCase(putAccountAdminLocation.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(putAccountAdminLocation.fulfilled, (state, { payload }) => {
          state.accountAdminInfo = { ...payload.data };
          state.isFetching = false;
        })
        .addCase(patchProfileData.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(patchProfileData.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(patchProfileData.fulfilled, (state, { payload }) => {
          state.data = payload.data;
          state.isFetching = false;
        })
        .addCase(fetchProfiles.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchProfiles.fulfilled, (state, { payload }) => {
          state.profiles = payload?.data || [];
          state.isFetching = false;
        })
        .addCase(fetchProfilesState.fulfilled, (state, { payload }) => {
          state.profilesState = payload?.data || [];
        })
        .addCase(fetchPlaylists.pending, (state) => {
          state.isFetchingPlaylist = true;
        })
        .addCase(fetchPlaylists.rejected, (state) => {
          state.isFetchingPlaylist = false;
        })
        .addCase(fetchPlaylists.fulfilled, (state, { payload }) => {
          state.playlist = payload.data;
          state.isFetchingPlaylist = false;
        })
        .addCase(generatePlaylists.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(generatePlaylists.fulfilled, (state) => {
          state.isFetching = false;
        })
        .addCase(refreshPlaylists.pending, (state) => {
          state.isFetchingPlaylist = true;
        })
        .addCase(refreshPlaylists.rejected, (state) => {
          state.isFetchingPlaylist = false;
        })
        .addCase(refreshPlaylists.fulfilled, (state, { payload }) => {
          state.playlist = payload.data;
          state.profiles.find((item) => {
            // @ts-ignore
            if (item.id === payload.profileId && item.playlists) {
              for (let i = 0; i < item.playlists.length; i += 1) {
                if (payload.data.name === item.playlists[i].name) {
                  item.playlists[i].id = payload.data.id;
                }
              }
            }
            return false;
          });
          state.isFetchingPlaylist = false;
        })
        .addCase(createProfile.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(createProfile.fulfilled, (state, { payload }) => {
          state.data = { ...payload };
          state.isFetching = false;
        })
        .addCase(updateProfile.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(updateProfile.fulfilled, (state, { payload }) => {
          state.data = { ...payload };
          state.isFetching = false;
        })
        .addCase(updatePassword.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(updatePassword.fulfilled, (state, { payload }) => {
          state.data = { ...payload };
          state.isFetching = false;
        })
        .addCase(fetchProfilePhoto.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchProfilePhoto.fulfilled, (state, { payload }) => {
          state.data.photo = payload?.data?.photo || null;
          state.isFetching = false;
        })
        .addCase(updateProfilePhoto.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(updateProfilePhoto.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(updateProfilePhoto.fulfilled, (state, { payload }) => {
          state.profiles.find((item) => {
            // @ts-ignore
            if (item.id === payload.profileId) {
              item.photo = payload.data.photo;
              return true;
            }
            return false;
          });
          state.isFetching = false;
        })
        .addCase(createProfilePro.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(createProfilePro.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(createProfilePro.fulfilled, (state, { payload }) => {
          state.data = { ...payload };
          state.isFetching = false;
        })
        .addCase(addExistingResident.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(addExistingResident.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(addExistingResident.fulfilled, (state, { payload }) => {
          state.isFetching = false;
        })
        .addCase(addNewResidentProfile.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(addNewResidentProfile.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(addNewResidentProfile.fulfilled, (state, { payload }) => {
          state.isFetching = false;
        })
        .addCase(remindInvitedResident.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(remindInvitedResident.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(remindInvitedResident.fulfilled, (state, { payload }) => {
          state.isFetching = false;
        })
        .addCase(removeInvitedResident.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(removeInvitedResident.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(removeInvitedResident.fulfilled, (state, { payload }) => {
          // @ts-ignore
          state.profiles = state.profiles.filter((item) => item.invite_id !== payload.invite_id);
          state.isFetching = false;
        })
        .addCase(archiveListenerProfile.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(archiveListenerProfile.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(archiveListenerProfile.fulfilled, (state, { payload }) => {
          state.isFetching = false;
        })
        .addCase(unarchiveListenerProfile.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(unarchiveListenerProfile.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(unarchiveListenerProfile.fulfilled, (state, { payload }) => {
          state.isFetching = false;
        })
        .addCase(fetchInvitedResidents.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(fetchInvitedResidents.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(fetchInvitedResidents.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          state.invites = [...payload.data];
        })
        .addCase(patchInvitedResidents.pending, (state) => {
          state.isFetching = true;
        })
        .addCase(patchInvitedResidents.rejected, (state) => {
          state.isFetching = false;
        })
        .addCase(patchInvitedResidents.fulfilled, (state, { payload }) => {
          state.isFetching = false;
          state.invites.find((item) => {
            if (item.id === payload.data.id) {
              item.status = payload.data.status;
              return true;
            }
            return false;
          });
        });
    },
  });

const {
  clearProfile,
  setActiveResident,
  setParnterId,
} = profileSlice.actions;

export {
  fetchProfileData,
  fetchPlaylists,
  fetchProfiles,
  refreshPlaylists,
  createProfile,
  updateProfile,
  patchProfileData,
  updatePassword,
  generatePlaylists,
  clearProfile,
  fetchProfilePhoto,
  updateProfilePhoto,
  fetchAccountAdminInfo,
  putAccountAdminLocation,
  createProfilePro,
  fetchProUserProfileData,
  addExistingResident,
  addNewResidentProfile,
  patchProUserProfileData,
  setActiveResident,
  remindInvitedResident,
  archiveListenerProfile,
  removeInvitedResident,
  fetchInvitedResidents,
  patchInvitedResidents,
  setParnterId,
  unarchiveListenerProfile,
  fetchProfilesState,
};

export default profileSlice.reducer;
