import React from 'react';
import classNames from 'classnames';
import Icon, { ICON_NAMES } from '../Icons';

interface ICheckbox {
  className?: string;
  disabled?: boolean;
  error?: string;
  value?: number | string | readonly string[] | undefined;
  onChange (event: React.ChangeEvent<any>): void;
  placeholder?: string;
  name: string;
  checked: boolean;
  button?: boolean;
  id: string;
  children?: React.ReactNode;
}

const Checkbox: React.FunctionComponent<ICheckbox> = ({
  className,
  disabled,
  error,
  value,
  onChange,
  placeholder,
  name,
  checked,
  button,
  id,
  children,
}) => (
  <label
    className={classNames('field field--checkbox', {
      [`${className}`]: className,
      'field--checked': checked,
      'field--disabled': disabled,
      'field--error': error,
    })}
    htmlFor={id}
  >
    <div
      className={classNames('checkbox', {
        'checkbox--button': button,
        'checkbox--checked': checked,
      })}
    >
      <input
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        value={value}
        name={name}
        id={id}
        checked={checked}
      />
      {
        button
          ? (
            <Icon
              name={ICON_NAMES.PLUS}
              active={checked}
              className="checkbox__icon"
            />
          )
          : (
            <span className="checkbox__marker">
              <img src="/images/check.png" alt="" />
            </span>
          )
      }
      <div className="checkbox__title">
        {placeholder || children}
      </div>
    </div>
  </label>
);

export default Checkbox;
