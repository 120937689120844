import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import ROUTES from './constants';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { REDUCER_KEY_AUTH } from '../constants';
import MusicLayout from '../layout/MusicLayout';
import { appendTokens } from '../store/reducers/authReducer';
import ApiClient from '../api/ApiClient';
import { fetchProfiles, fetchProUserProfileData } from '../store/reducers/profileReducer';
import usePro from '../hooks/usePro';

const MusicPlayerRoute: React.FunctionComponent<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state) => ({
    auth: state[REDUCER_KEY_AUTH],
  }));
  const history = useHistory();
  const { isPro } = usePro();
  const searchParams = new URLSearchParams(window.location.search);
  const tokenExternal = searchParams.get('token');
  const refreshTokenExternal = searchParams.get('refreshToken');

  useEffect(() => {
    (async () => {
      if (refreshTokenExternal && tokenExternal) {
        const response = await ApiClient.refreshUserToken(refreshTokenExternal);
        const token = response.data.access;
        dispatch(appendTokens({ token, refreshToken: refreshTokenExternal }));
        ApiClient.setToken(response.data.access, refreshTokenExternal);
        await dispatch(fetchProfiles());
        if (isPro) {
          await dispatch(fetchProUserProfileData());
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!Component) return null;

  if (
    auth.isRegistering
    && history.location.pathname !== ROUTES.LOGIN
    && history.location.pathname !== ROUTES.SIGNUP
  ) {
    return <Redirect to={ROUTES.CONTINUE_ONBOARDING} />;
  }

  if (
    auth.isGuest
    && (!auth.token || !auth.refreshToken)
    && !tokenExternal
    && !refreshTokenExternal
  ) {
    return <Redirect to={ROUTES.START} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <MusicLayout>
          <Component {...props} />
        </MusicLayout>
      )}
    />
  );
};

export default MusicPlayerRoute;
