import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { PersistGate } from 'redux-persist/integration/react';
import { setupResponseInterceptor } from './api/AxiosClient';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store';
import './styles/styles.scss';
import { REACT_APP_INTERCOM_APP_ID } from './config';

const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV ?? 'development',
  });
}

setupResponseInterceptor(store);

const intercomAppId = REACT_APP_INTERCOM_APP_ID;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntercomProvider appId={`${intercomAppId}`}>
          <App />
        </IntercomProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  // eslint-disable-next-line
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
